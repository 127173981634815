<template>
  <div>
    <h2>Test</h2>
  </div>
</template>

<script>
import axios from "axios";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  components: {},

  data() {
    return {
      taxiserviceId: null,
      //nastavenia modulov
      module: moduleDefaultSettings,
    };
  },

  created() {
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    this.getTaxiserviceSetting();
  },

  methods: {
    getTaxiserviceSetting() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/${this.taxiserviceId}`
        )
        .then((response) => {
          this.item = response.data;
          this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getItemsSupportOrderTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/support-type-order`
        )
        .then((response) => {
          this.gridItemsSupportOrderTypes = response.data;
          //this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getPaymentOption() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/payment/option`)
        .then((response) => {
          this.gridItems = response.data;
          this.gridItemsClone = JSON.parse(JSON.stringify(this.gridItems));
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getDriverList() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/driver?enabled=true`
        )
        .then((response) => {
          var data = response.data;
          data.forEach((element) => {
            if (
              element.driverLicenceExpiration === null ||
              element.driverLicenceExpiration < new Date().toISOString() ||
              element.driverLicenceNumber === null
            ) {
              element.licenceStatus = "NOKD";
            } else if (
              element.driverTaxiLicenceExpiration === null ||
              element.driverTaxiLicenceExpiration < new Date().toISOString() ||
              element.driverTaxiLicenceNumber === null
            ) {
              element.licenceStatus = "NOKT";
            } else {
              element.licenceStatus = "OK";
            }
          });
          this.gridItems = data;
        })
        .catch((e) => {
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getVehicleList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`)
        .then((response) => {
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getItemsLocalityPickup() {
      //console.log("Function Name", "getItemsLocalityPickup");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-pickup`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList) {
            if (itemList.constructor.name != "Array") {
              var newItemList = [];
              newItemList.push(itemList);
              itemList = newItemList;
            }
          }
          this.gridItemsLocalityPickup = itemList;
          this.passDataOnMap = itemList;
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          this.localityPickupCreateCurrent = itemList.length;
          var localityPickupCreateMax = this.localityPickupCreateMax;
          var localityPickupCreateCurrent = this.localityPickupCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          //console.log(            "localityPickupCreateCurrent",            localityPickupCreateCurrent,            "localityPickupCreateMax",            localityPickupCreateMax          );
          if (localityPickupCreateCurrent >= localityPickupCreateMax) {
            this.localityPickupCreateButtonDisabled = true;
          } else {
            this.localityPickupCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    getItemsLocalityStand() {
      //console.log("Function Name", "getItemsLocalityStand");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //ID zobrazovať iba adminovi
          if (this.displayOnlyForAdmin == false) {
            this.gridHeadersStand = this.gridHeadersStand.filter(
              (item) => item.value != "id"
            );
          }
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          this.gridItemsLocalityStand = itemList;
          this.passDataOnMap = itemList;

          //skontrolovat ci je defaultna lokalita existuje

          let defaultStandExistsCounter = 0;
          itemList.forEach((item) => {
            if (item.defaultStand == true) {
              //increment counter
              defaultStandExistsCounter++;
            }
          });
          if (defaultStandExistsCounter == 0) {
            //snakebar
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "LocalityList.msgDefaultStandNotSet"
            );
            this.snackbar.color = "warning";
          } else if (defaultStandExistsCounter > 1) {
            //snakebar
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "LocalityList.msgDefaultStandMoreThenOne"
            );
            this.snackbar.color = "warning";
          }
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          this.localityStandCreateCurrent = itemList.length;
          var localityStandCreateMax = this.localityStandCreateMax;
          var localityStandCreateCurrent = this.localityStandCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          //console.log(            "localityStandCreateCurrent",            localityStandCreateCurrent,            "localityStandCreateMax",            localityStandCreateMax          );
          if (localityStandCreateCurrent >= localityStandCreateMax) {
            this.localityStandCreateButtonDisabled = true;
          } else {
            this.localityStandCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    getItemsLocalityPrice() {
      //console.log("Function Name", "getItemsLocalityPrice");
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-price`)
        .then((response) => {
          //ID zobrazovať iba adminovi
          if (this.displayOnlyForAdmin == false) {
            this.gridHeadersLocalityPrice =
              this.gridHeadersLocalityPrice.filter(
                (item) => item.value != "id"
              );
          }
          this.gridItemsLocalityPrice = response.data;
          this.passDataOnMap = response.data;
          //display only active items
          if (this.localityPriceActiveOnly) {
            this.gridItemsLocalityPrice = this.gridItemsLocalityPrice.filter(
              (item) => item.active == true
            );
            this.passDataOnMap = this.passDataOnMap.filter(
              (item) => item.active == true
            );
          }
          //this.cachedItemLocalityPrice = Object.assign({}, this.gridItemsLocalityPrice);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          //this.initLocalityPriceListMap();
          //Nastavenia blokovani tlacidiel na vytvania novych lokalit a counter lokalit
          //localityPriceShapes.length = 0;
          //this.localityPriceCreateMax = 10;
          //this.localityPriceCreateCurrent = this.gridItemsLocalityPrice.length;
          this.localityPriceCreateCurrent = response.data.length;
          var localityPriceCreateMax = this.localityPriceCreateMax;
          var localityPriceCreateCurrent = this.localityPriceCreateCurrent;
          //Zablokovanie tlacidiel na vytvaranie novy lokalit, ak je ich viac ako 10
          if (localityPriceCreateCurrent >= localityPriceCreateMax) {
            this.localityPriceCreateButtonDisabled = true;
          } else {
            this.localityPriceCreateButtonDisabled = false;
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },
  },
};
</script>
