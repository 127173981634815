<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("Settings.servicePlan_page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-car</v-icon>
    </v-toolbar>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.icon") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.name") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.desctription") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.status") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.unitPrice") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.actualPrice") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.changeValue") }}
            </th>
            <th class="text-left">
              {{ $t("Settings.tableHeadersSupportModules.newPrice") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-icon> mdi-car-multiple </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.limitWorkshift") }}</b>
              {{ !readOnlyMainSettings ? "\nlimitWorkshift" : "" }}
            </td>
            <td>
              {{
                $t("Settings.tableRowsSupportModules.limitWorkshiftDescription")
              }}
            </td>
            <td>{{ module.limitWorkshift }}</td>
            <td>{{ servicePriceUnit.limitWorkshift }}</td>
            <td>{{ servicePrice.limitWorkshift }}</td>
            <td>
              <v-text-field
                v-model="newValue.limitWorkshift"
                type="number"
                class="mt-0 pt-0"
                @input="countNewServicePrice()"
                :rules="limitWorkshiftRules"
              ></v-text-field>
            </td>
            <td>{{ servicePriceNew.limitWorkshift }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-credit-card-multiple-outline </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.cardPayments") }}</b>
              {{ !readOnlyMainSettings ? "\ncardPayments" : "" }}
            </td>
            <td>
              {{
                $t("Settings.tableRowsSupportModules.cardPaymentsDescription")
              }}
            </td>
            <td>
              <v-icon v-if="module.cardPayments" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.cardPayments }}</td>
            <td>{{ servicePrice.cardPayments }}</td>
            <td>
              <v-checkbox
                v-model="newValue.cardPayments"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.cardPayments }}</td>
          </tr>
          <tr>
            <td>
              <v-icon> mdi-sale-outline </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.discount") }}</b>
              {{ !readOnlyMainSettings ? "\ndiscount" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.discountDescription") }}
            </td>
            <td>
              <v-icon v-if="module.discount" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.discount }}</td>
            <td>{{ servicePrice.discount }}</td>
            <td>
              <v-checkbox
                v-model="newValue.discount"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.discount }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-chat-outline </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{
                $t("Settings.tableRowsSupportModules.dispatchingChat")
              }}</b>
              {{ !readOnlyMainSettings ? "\ndispatchingChat" : "" }}
            </td>
            <td>
              {{
                $t(
                  "Settings.tableRowsSupportModules.dispatchingChatDescription"
                )
              }}
            </td>
            <td>
              <v-icon v-if="module.dispatchingChat" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.dispatchingChat }}</td>
            <td>{{ servicePrice.dispatchingChat }}</td>
            <td>
              <v-checkbox
                v-model="newValue.dispatchingChat"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.dispatchingChat }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-web </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{
                $t("Settings.tableRowsSupportModules.limitLocalityPrice")
              }}</b>
              {{ !readOnlyMainSettings ? "\nlimitLocalityPrice" : "" }}
            </td>
            <td>
              {{
                $t(
                  "Settings.tableRowsSupportModules.limitLocalityPriceDescription"
                )
              }}
            </td>
            <td>{{ module.limitLocalityPrice }}</td>
            <td>{{ servicePriceUnit.limitLocalityPrice }}</td>
            <td>{{ servicePrice.limitLocalityPrice }}</td>
            <td>
              <v-text-field
                v-model="newValue.limitLocalityPrice"
                type="number"
                class="mt-0 pt-0"
                @input="countNewServicePrice()"
                :rules="limitLocalityPriceRules"
              ></v-text-field>
            </td>
            <td>{{ servicePriceNew.limitLocalityPrice }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-car-select </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.limitStand") }}</b>
              {{ !readOnlyMainSettings ? "\nlimitStand" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.limitStandDescription") }}
            </td>
            <td>{{ module.limitStand }}</td>
            <td>{{ servicePriceUnit.limitStand }}</td>
            <td>{{ servicePrice.limitStand }}</td>
            <td>
              <v-text-field
                v-model="newValue.limitStand"
                type="number"
                class="mt-0 pt-0"
                @input="countNewServicePrice()"
                :rules="limitStandRules"
              ></v-text-field>
            </td>
            <td>{{ servicePriceNew.limitStand }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-cash </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.pricelistType") }}</b>
              {{ !readOnlyMainSettings ? "\npricelistType" : "" }}
            </td>
            <td>
              {{
                $t("Settings.tableRowsSupportModules.pricelistTypeDescription")
              }}
            </td>
            <td>{{ $t("ModulePricelistType." + module.pricelistType) }}</td>
            <td>{{ servicePriceUnit.pricelistType }}</td>
            <td>{{ servicePrice.pricelistType }}</td>
            <!-- dropdown with pricelist types -->
            <td>
              <v-select
                v-model="newValue.pricelistType"
                :items="$t('ModulePricelistTypeArray')"
                item-text="text"
                item-value="value"
                @change="countNewServicePrice()"
                :rules="pricelistTypeRules"
              ></v-select>
            </td>
            <td>{{ servicePriceNew.pricelistType }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-finance </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.report") }}</b>
              {{ !readOnlyMainSettings ? "\nreport" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.reportDescription") }}
            </td>
            <td>
              <v-icon v-if="module.report" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.report }}</td>
            <td>{{ servicePrice.report }}</td>
            <td>
              <v-checkbox
                v-model="newValue.report"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.report }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-car-clock </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.timeOrder") }}</b>
              {{ !readOnlyMainSettings ? "\ntimeOrder" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.timeOrderDescription") }}
            </td>
            <td>
              <v-icon v-if="module.timeOrder" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.timeOrder }}</td>
            <td>{{ servicePrice.timeOrder }}</td>
            <td>
              <v-checkbox
                v-model="newValue.timeOrder"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.timeOrder }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-store </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.account") }}</b>
              {{ !readOnlyMainSettings ? "\naccount" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.accountDescription") }}
            </td>
            <td>
              <v-icon v-if="module.account" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.account }}</td>
            <td>{{ servicePrice.account }}</td>
            <td>
              <v-checkbox
                v-model="newValue.account"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.account }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-car-connected </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.worklog") }}</b>
              {{ !readOnlyMainSettings ? "\nworklog" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.worklogDescription") }}
            </td>
            <td>
              <v-icon v-if="module.worklog" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.worklog }}</td>
            <td>{{ servicePrice.worklog }}</td>
            <td>
              <v-checkbox
                v-model="newValue.worklog"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.worklog }}</td>
          </tr>
          <!-- addressSearch -->
          <tr>
            <td>
              <v-icon> mdi-map-search-outline </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.addressSearch") }}</b>
              {{ !readOnlyMainSettings ? "\naddressSearch" : "" }}
            </td>
            <td>
              {{
                $t("Settings.tableRowsSupportModules.addressSearchDescription")
              }}
            </td>
            <td>
              <v-icon v-if="module.addressSearch" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.addressSearch }}</td>
            <td>{{ servicePrice.addressSearch }}</td>
            <!-- dropdown with pricelist types -->
            <td>
              <v-select
                v-model="newValue.addressSearch"
                :items="$t('ModuleAddressSearchArray')"
                item-text="text"
                item-value="value"
                @change="countNewServicePrice()"
              ></v-select>
            </td>
            <td>{{ servicePriceNew.addressSearch }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-cellphone </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.brandApp") }}</b>
              {{ !readOnlyMainSettings ? "\nbrandApp" : "" }}
            </td>
            <td>
              {{ $t("Settings.tableRowsSupportModules.brandAppDescription") }}
            </td>
            <td>
              <v-icon v-if="module.brandApp" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.brandApp }}</td>
            <td>{{ servicePrice.brandApp }}</td>
            <td>
              <v-checkbox
                v-model="newValue.brandApp"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.brandApp }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-advertisements </v-icon>
            </td>
            <td style="white-space: pre-wrap">
              <b>{{ $t("Settings.tableRowsSupportModules.commercialAds") }}</b>
              {{ !readOnlyMainSettings ? "\ncommercialAds" : "" }}
            </td>
            <td>
              {{
                $t("Settings.tableRowsSupportModules.commercialAdsDescription")
              }}
            </td>
            <td>
              <v-icon v-if="module.worklog" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-else color="red"> mdi-cancel </v-icon>
            </td>
            <td>{{ servicePriceUnit.commercialAds }}</td>
            <td>{{ servicePrice.commercialAds }}</td>
            <td>
              <v-checkbox
                v-model="newValue.commercialAds"
                color="primary"
                @change="countNewServicePrice()"
              ></v-checkbox>
            </td>
            <td>{{ servicePriceNew.commercialAds }}</td>
          </tr>

          <tr>
            <td>
              <v-icon> mdi-sigma</v-icon>
            </td>
            <td>
              <b>{{ $t("Settings.servicePlan_totalPrice") }}</b>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <b>{{ servicePriceTotal }}</b>
            </td>
            <td>-</td>
            <td>
              <b>{{ servicePriceTotalNew }}</b>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  data() {
    return {
      taxiserviceId: null, //ID taxisluzby
      //nastavenia modulov
      module: moduleDefaultSettings,
      readOnlyMainSettings: true, //Pre role taxisluzby niektore polia zablokovane. Pre role taxxim sú povolené.
      loadingData: false,
      servicePriceTotal: 0,
      servicePriceTotalNew: 0,

      servicePriceUnit: {
        account: 0,
        cardPayments: 0,
        discount: 0,
        dispatchingChat: 0,
        limitLocalityPrice: 0,
        limitStand: 0,
        limitWorkshift: 0,
        pricelistType: 0,
        report: 0,
        timeOrder: 0,
        worklog: 0,
        addressSearch: 0,
        brandApp: 0,
        commercialAds: 0,
      },
      servicePrice: {
        account: 0,
        cardPayments: 0,
        discount: 0,
        dispatchingChat: 0,
        limitLocalityPrice: 0,
        limitStand: 0,
        limitWorkshift: 0,
        pricelistType: 0,
        report: 0,
        timeOrder: 0,
        worklog: 0,
        addressSearch: 0,
        brandApp: 0,
        commercialAds: 0,
      },
      newValue: {
        account: false,
        cardPayments: false,
        discount: false,
        dispatchingChat: false,
        limitLocalityPrice: 1,
        limitStand: 1,
        limitWorkshift: 1,
        pricelistType: "SIMPLE",
        report: false,
        timeOrder: false,
        worklog: false,
        addressSearch: false,
        brandApp: false,
        commercialAds: false,
      },
      servicePriceNew: {
        account: 0,
        cardPayments: 0,
        discount: 0,
        dispatchingChat: 0,
        limitLocalityPrice: 0,
        limitStand: 0,
        limitWorkshift: 0,
        pricelistType: 0,
        report: 0,
        timeOrder: 0,
        worklog: 0,
        addressSearch: 0,
        brandApp: 0,
        commercialAds: 0,
      },
      servicePlan: [
        {
          type: "account",
          subtype: "NONE",
          price: 50,
        },
        {
          type: "cardPayments",
          subtype: "NONE",
          price: 20,
        },
        {
          type: "discount",
          subtype: "NONE",
          price: 20,
        },
        {
          type: "dispatchingChat",
          subtype: "NONE",
          price: 10,
        },
        {
          type: "limitLocalityPrice",
          subtype: "NONE",
          price: 2,
        },
        {
          type: "limitStand",
          subtype: "NONE",
          price: 5,
        },
        {
          type: "limitWorkshift",
          subtype: "NONE",
          price: 16,
        },
        {
          type: "pricelistType",
          subtype: "SIMPLE",
          price: 20,
        },
        {
          type: "pricelistType",
          subtype: "LOCALITY",
          price: 50,
        },
        {
          type: "pricelistType",
          subtype: "EXTENDED",
          price: 100,
        },
        {
          type: "report",
          subtype: "NONE",
          price: 20,
        },
        {
          type: "timeOrder",
          subtype: "NONE",
          price: 20,
        },
        {
          type: "worklog",
          subtype: "NONE",
          price: 20,
        },
        { type: "addressSearch", subtype: "DB_GOOGLE", price: 3 },
        { type: "addressSearch", subtype: "DB_OSM", price: 1 },
        { type: "addressSearch", subtype: "DB_GEOCODE", price: 2 },
        { type: "addressSearch", subtype: "GOOGLE", price: 40 },
        { type: "addressSearch", subtype: "OSM", price: 3 },
        { type: "addressSearch", subtype: "GEOCODE", price: 5 },
        {
          type: "brandApp",
          subtype: "NONE",
          price: 99,
        },
        {
          type: "commercialAds",
          subtype: "NONE",
          price: 50,
        },
      ],

      limitWorkshiftRules: [
        (v) => !!v || this.$t("Settings.limitWorkshiftRules1"), //"This field is required",
        (v) => (v && v >= 3) || this.$t("Settings.limitWorkshiftRules2"), //"Value should be above 3",
        (v) => (v && v <= 500) || this.$t("Settings.limitWorkshiftRules3"), //"Max should not be above 500",
      ],
      limitStandRules: [
        (v) => !!v || this.$t("Settings.limitStandRules1"), //"This field is required",
        (v) => (v && v >= 0) || this.$t("Settings.limitStandRules2"), //"Value should be above 0",
        (v) =>
          (v && v >= this.module.limitStand) ||
          this.$t("Settings.limitStandRules3"), //"Value should be above your current limit",
        (v) => (v && v <= 20) || this.$t("Settings.limitStandRules4"), //"Max should not be above 20",
      ],
      limitLocalityPriceRules: [
        (v) => !!v || this.$t("Settings.limitLocalityPriceRules1"), //"This field is required",
        (v) => (v && v >= 0) || this.$t("Settings.limitLocalityPriceRules2"), //"Value should be above 0",
        (v) =>
          (v && v >= this.module.limitLocalityPrice) ||
          this.$t("Settings.limitLocalityPriceRules3"), //"Value should be above your current limit",
        (v) => (v && v <= 20) || this.$t("Settings.limitLocalityPriceRules4"), //"Max should not be above 20",
      ],
      //pricelistType môže byť rovnaký ako je teraz alebo iný
      //avšak ak je aktuálny LOCALITY alebo EXTENDED tak sa nový nesmie zmeniť na SIMPLE,
      //Taktiež ak je aktuálny EXTENDED tak nesmie byť nový SIMPLE ani LOCALITY
      pricelistTypeRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (v && v !== "SIMPLE") ||
          (this.module.pricelistType !== "LOCALITY" &&
            this.module.pricelistType !== "EXTENDED") ||
          "You can't change pricelist type to SIMPLE",
        (v) =>
          (v && v !== "LOCALITY") ||
          (this.module.pricelistType !== "EXTENDED" &&
            this.module.pricelistType !== "SIMPLE") ||
          "You can't change pricelist type to LOCALITY",
        (v) =>
          (v && v !== "EXTENDED") ||
          (this.module.pricelistType !== "LOCALITY" &&
            this.module.pricelistType !== "SIMPLE") ||
          "You can't change pricelist type to EXTENDED",
      ],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  created() {
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    //this.getAll();
    let userRoles = this.$store.state.user.roleList;
    let enabledRoles = ["ADMIN", "SUPPORT"];
    //this.readOnlyMainSettings = userRoles.some((a) => (enabledRoles.includes(a) ? false : true)) == true  ? true : false;
    this.countServicePrice();
    this.actualPriceToNew();
    this.countNewServicePrice();
  },

  methods: {
    /* getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t('msgResponseError');
          this.snackbar.color = "error";
        });
    },*/
    actualPriceToNew() {
      this.newValue.account = this.module.account;
      this.newValue.cardPayments = this.module.cardPayments;
      this.newValue.discount = this.module.discount;
      this.newValue.dispatchingChat = this.module.dispatchingChat;
      this.newValue.limitLocalityPrice = this.module.limitLocalityPrice;
      this.newValue.limitStand = this.module.limitStand;
      this.newValue.limitWorkshift = this.module.limitWorkshift;
      this.newValue.pricelistType = this.module.pricelistType;
      this.newValue.report = this.module.report;
      this.newValue.timeOrder = this.module.timeOrder;
      this.newValue.worklog = this.module.worklog;
      this.newValue.addressSearch = this.module.addressSearch;
      this.newValue.brandApp = this.module.brandApp;
      this.newValue.commercialAds = this.module.commercialAds;
    },

    countServicePrice() {
      // find price in servicePlan by type limitWorkshift
      this.servicePriceUnit.limitWorkshift = this.servicePlan.find(
        (x) => x.type === "limitWorkshift"
      ).price;
      this.servicePrice.limitWorkshift =
        this.servicePriceUnit.limitWorkshift * this.module.limitWorkshift;

      //find price in servicePlan by type pricelistType and subtype module.pricelistType
      this.servicePriceUnit.pricelistType = this.servicePlan.find(
        (x) =>
          x.type === "pricelistType" && x.subtype === this.module.pricelistType
      ).price;
      this.servicePrice.pricelistType = this.servicePriceUnit.pricelistType;

      //find price in servicePlan by type limitLocalityPrice
      this.servicePriceUnit.limitLocalityPrice = this.servicePlan.find(
        (x) => x.type === "limitLocalityPrice"
      ).price;
      this.servicePrice.limitLocalityPrice =
        this.servicePriceUnit.limitLocalityPrice *
        this.module.limitLocalityPrice;

      //find price in servicePlan by type limitStand
      this.servicePriceUnit.limitStand = this.servicePlan.find(
        (x) => x.type === "limitStand"
      ).price;
      this.servicePrice.limitStand =
        this.servicePriceUnit.limitStand * this.module.limitStand;

      //find price in servicePlan by type timeOrder
      this.servicePriceUnit.timeOrder = this.servicePlan.find(
        (x) => x.type === "timeOrder"
      ).price;
      if (this.module.timeOrder == false) this.servicePrice.timeOrder = 0;
      else this.servicePrice.timeOrder = this.servicePriceUnit.timeOrder;

      //find price in servicePlan by type account
      this.servicePriceUnit.account = this.servicePlan.find(
        (x) => x.type === "account"
      ).price;
      if (this.module.account == false) this.servicePrice.account = 0;
      else this.servicePrice.account = this.servicePriceUnit.account;

      //find price in servicePlan by type cardPayments
      this.servicePriceUnit.cardPayments = this.servicePlan.find(
        (x) => x.type === "cardPayments"
      ).price;
      if (this.module.cardPayments == false) this.servicePrice.cardPayments = 0;
      else this.servicePrice.cardPayments = this.servicePriceUnit.cardPayments;

      //find price in servicePlan by type discount
      this.servicePriceUnit.discount = this.servicePlan.find(
        (x) => x.type === "discount"
      ).price;
      if (this.module.discount == false) this.servicePrice.discount = 0;
      else this.servicePrice.discount = this.servicePriceUnit.discount;

      //find price in servicePlan by type dispatchingChat
      this.servicePriceUnit.dispatchingChat = this.servicePlan.find(
        (x) => x.type === "dispatchingChat"
      ).price;
      if (this.module.dispatchingChat == false)
        this.servicePrice.dispatchingChat = 0;
      else
        this.servicePrice.dispatchingChat =
          this.servicePriceUnit.dispatchingChat;

      //find price in servicePlan by type report
      this.servicePriceUnit.report = this.servicePlan.find(
        (x) => x.type === "report"
      ).price;
      if (this.module.report == false) this.servicePrice.report = 0;
      else this.servicePrice.report = this.servicePriceUnit.report;

      //find price in servicePlan by type worklog
      this.servicePriceUnit.worklog = this.servicePlan.find(
        (x) => x.type === "worklog"
      ).price;
      if (this.module.worklog == false) this.servicePrice.worklog = 0;
      else this.servicePrice.worklog = this.servicePriceUnit.worklog;

      //find price in servicePlan by type addressSearch
      this.servicePriceUnit.addressSearch = this.servicePlan.find(
        (x) =>
          x.type === "addressSearch" && x.subtype === this.module.addressSearch
      ).price;
      this.servicePrice.addressSearch =
        this.servicePriceUnit.addressSearch * this.module.limitWorkshift;

      //find price in servicePlan by type brandApp
      this.servicePriceUnit.brandApp = this.servicePlan.find(
        (x) => x.type === "brandApp"
      ).price;
      if (this.module.brandApp == false) this.servicePrice.brandApp = 0;
      else this.servicePrice.brandApp = this.servicePriceUnit.brandApp;

      //find price in servicePlan by type commercialAds
      this.servicePriceUnit.commercialAds = this.servicePlan.find(
        (x) => x.type === "commercialAds"
      ).price;
      if (this.module.commercialAds == false)
        this.servicePrice.commercialAds = 0;
      else
        this.servicePrice.commercialAds = this.servicePriceUnit.commercialAds;

      this.servicePriceTotal =
        this.servicePrice.limitWorkshift +
        this.servicePrice.pricelistType +
        this.servicePrice.limitLocalityPrice +
        this.servicePrice.limitStand +
        this.servicePrice.timeOrder +
        this.servicePrice.account +
        this.servicePrice.cardPayments +
        this.servicePrice.discount +
        this.servicePrice.dispatchingChat +
        this.servicePrice.report +
        this.servicePrice.worklog +
        this.servicePrice.addressSearch +
        this.servicePrice.brandApp +
        this.servicePrice.commercialAds;
    },

    countNewServicePrice() {
      //limitWorkshift
      this.servicePriceNew.limitWorkshift =
        this.servicePriceUnit.limitWorkshift * this.newValue.limitWorkshift;

      //pricelistType
      var pricelistTypeUnit = this.servicePlan.find(
        (x) =>
          x.type === "pricelistType" &&
          x.subtype === this.newValue.pricelistType
      ).price;
      this.servicePriceNew.pricelistType = pricelistTypeUnit;

      //limitLocalityPrice
      this.servicePriceNew.limitLocalityPrice =
        this.servicePriceUnit.limitLocalityPrice *
        this.newValue.limitLocalityPrice;

      //limitStand
      this.servicePriceNew.limitStand =
        this.servicePriceUnit.limitStand * this.newValue.limitStand;

      //timeOrder
      if (this.newValue.timeOrder == false) this.servicePriceNew.timeOrder = 0;
      else this.servicePriceNew.timeOrder = this.servicePriceUnit.timeOrder;

      //account
      if (this.newValue.account == false) this.servicePriceNew.account = 0;
      else this.servicePriceNew.account = this.servicePriceUnit.account;

      //cardPayments
      if (this.newValue.cardPayments == false)
        this.servicePriceNew.cardPayments = 0;
      else
        this.servicePriceNew.cardPayments = this.servicePriceUnit.cardPayments;

      //discount
      if (this.newValue.discount == false) this.servicePriceNew.discount = 0;
      else this.servicePriceNew.discount = this.servicePriceUnit.discount;

      //dispatchingChat
      if (this.newValue.dispatchingChat == false)
        this.servicePriceNew.dispatchingChat = 0;
      else
        this.servicePriceNew.dispatchingChat =
          this.servicePriceUnit.dispatchingChat;

      //report
      if (this.newValue.report == false) this.servicePriceNew.report = 0;
      else this.servicePriceNew.report = this.servicePriceUnit.report;

      //worklog
      if (this.newValue.worklog == false) this.servicePriceNew.worklog = 0;
      else this.servicePriceNew.worklog = this.servicePriceUnit.worklog;

      //addressSearch
      var addressSearchUnit = this.servicePlan.find(
        (x) =>
          x.type === "addressSearch" &&
          x.subtype === this.newValue.addressSearch
      ).price;
      this.servicePriceNew.addressSearch =
        addressSearchUnit * this.newValue.limitWorkshift;

      //brandApp
      if (this.newValue.brandApp == false) this.servicePriceNew.brandApp = 0;
      else this.servicePriceNew.brandApp = this.servicePriceUnit.brandApp;

      //commercialAds
      if (this.newValue.commercialAds == false)
        this.servicePriceNew.commercialAds = 0;
      else
        this.servicePriceNew.commercialAds =
          this.servicePriceUnit.commercialAds;

      this.servicePriceTotalNew =
        this.servicePriceNew.limitWorkshift +
        this.servicePriceNew.pricelistType +
        this.servicePriceNew.limitLocalityPrice +
        this.servicePriceNew.limitStand +
        this.servicePriceNew.timeOrder +
        this.servicePriceNew.account +
        this.servicePriceNew.cardPayments +
        this.servicePriceNew.discount +
        this.servicePriceNew.dispatchingChat +
        this.servicePriceNew.report +
        this.servicePriceNew.worklog +
        this.servicePriceNew.addressSearch +
        this.servicePriceNew.brandApp +
        this.servicePriceNew.commercialAds;
    },
  },
};
</script>
