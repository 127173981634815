<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("PricelistList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-money-bill</v-icon>
    </v-toolbar>

    <v-btn
      small
      class="my-2"
      color="success"
      :disabled="disableCreateButton"
      @click.stop="openCreateDialog()"
    >
      {{ $t("btnCreate") }}
    </v-btn>

    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`item.validFrom`]="{ item }">
        <span>{{ $options.filters.formatDateLong(item.validFrom) }}</span>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        <v-chip small :color="getServiceColor(item.service)" dark label>
          {{ item.service }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" small @click="getAll()">{{
          $t("btnReload")
        }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="detail(item.id)">fa-eye</v-icon>
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
      </template>
    </v-data-table>

    <v-dialog
      v-model="createDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("PricelistList.newPricelistDialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="createItem.name"
                    :title="$t('PricelistList.name')"
                    :label="$t('PricelistList.name')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="createItem.service"
                    :title="$t('PricelistList.service')"
                    :label="$t('PricelistList.service')"
                    filled
                    :items="$t('PricelistEnums.ServiceArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <my-datepicker
                    v-model="createItem.validFrom"
                    :title="$t('PricelistList.validFrom')"
                    :label="$t('PricelistList.validFrom')"
                    filled
                  >
                  </my-datepicker>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="createDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import {
  PriceStartFareType,
  PriceDriveType,
  Service,
  Status,
} from "../enums/PricelistEnum";

export default {
  data() {
    return {
      Service,
      statusEnum: Status,

      moment: moment,
      gridHeaders: [
        {
          text: this.$t("PricelistList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        { text: this.$t("PricelistList.tableHeaders.name"), value: "name" },
        {
          text: this.$t("PricelistList.tableHeaders.active"),
          value: "active",
        },
        {
          text: this.$t("PricelistList.tableHeaders.validFrom"),
          value: "validFrom",
        },
        {
          text: this.$t("PricelistList.tableHeaders.service"),
          value: "service",
        },
        {
          text: this.$t("PricelistList.tableHeaders.currency"),
          value: "currency",
        },
      ],
      gridItems: [],

      loadingData: false,
      disableCreateButton: true,
      createDefaultItem: {
        name: "",
        service: Service.PERSONAL,
        validFrom: moment(),
      },
      createItem: {},

      createDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/pricelist`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
          //if 1 and more items, disable create button
          console.log(this.gridItems.length);
          if (response.data.length > 0) {
            this.disableCreateButton = true;
          } else {
            this.disableCreateButton = false;
          }
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },

    openCreateDialog() {
      this.createItem = Object.assign({}, this.createDefaultItem);
      this.createDialog = true;
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/pricelist`,
            this.createItem
          )
          .then((response) => {
            this.createDialog = false;
            this.detail(response.data.id);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },

    getStatusColor(status) {
      switch (status) {
        case this.statusEnum.ACTIVE:
          return "green";
        default:
          return "black";
      }
    },
    getServiceColor(service) {
      switch (service) {
        case Service.PERSONAL:
          return "purple";
        case Service.LIMO:
          return "lime";
        case Service.CARGO:
          return "red";
        case Service.DRINK:
          return "orange";
        case Service.IMMOBILE:
          return "brown";
        case Service.COURIER:
          return "green";
        case Service.DELIVERY:
          return "teal";
        case Service.AIRPORT:
          return "blue";
        case Service.SOCIAL:
          return "yellow";
        default:
          return "black";
      }
    },
  },
};
</script>
