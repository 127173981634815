export const Type = Object.freeze({
  DISABLED:"DISABLED", 	// Neaktívny, negenerovať doklad (0)
  INVOICE:"INVOICE", 	// Faktúra daňový doklad (1)
  RECEIPT:"RECEIPT", 	// Cash Receipt / Pokladničný blok, daňový doklad (2)
  CREDIT_NOTE:"CREDIT_NOTE", 	// Dobropis, daňový doklad (3)
  NF_INVOICE:"NF_INVOICE", 	// Non Fiscal Invoice / Faktúra NEdaňový doklad (1)
  NF_RECEIPT:"NF_RECEIPT", 	// Non Fiscal Cash Receipt / Pokladničný blok, NEdaňový doklad (2)
  NF_CREDIT_NOTE:"NF_CREDIT_NOTE", 	// Non Fiscal Credit Note / Dobropis, NEdaňový doklad (3)
  PRO_FORMA:"PRO_FORMA" 	// Zálohová faktúra, NEdaňový doklad (14+4)
});


export const Status = Object.freeze({
  CANNED:"CANNED", 	// Predpripravená (1)
  ISSUED:"ISSUED", 	// Vystavená (2)
  POSTED:"POSTED", 	// Vyúčtovaná (3)
  PAID:"PAID", 	// Zaplatená (4)
  ACCOUNTED:"ACCOUNTED", 	// Zaúčtovaná (5)
  CREDIT:"CREDIT", 	// Dobropisovaná (6)
  RECLAIMED:"RECLAIMED", 	// Reklamovaná (7)
  DELAYED:"DELAYED", 	// V omeškaní (8)
  CLAIMED:"CLAIMED", 	// Vymáhaná (8)
  CANCELLED:"CANCELLED" 	// Zrusena (10)
});