<template>
  <div v-if="item">
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Taxiservice.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fas fa-city</v-icon>
      <template v-slot:extension>
        <v-tabs
          icons-and-text
          v-model="tabs"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1"
            >{{ $t("Settings.tabInfo") }}<v-icon small>fa-info</v-icon></v-tab
          >
          <v-tab key="tab2"
            >{{ $t("Settings.tabAutomat")
            }}<v-icon small>mdi-robot</v-icon></v-tab
          >
          <v-tab key="tab3"
            >{{ $t("Settings.tabSettings")
            }}<v-icon small>fa-cog</v-icon></v-tab
          >
          <v-tab key="tab4"
            >{{ $t("Settings.tabContacts")
            }}<v-icon small>fa-phone</v-icon></v-tab
          ><v-tab key="tab5"
            >{{ $t("Settings.tabGateway") }}
            <v-icon small>mdi-phone-classic</v-icon></v-tab
          >
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs" touchless>
      <!-- TAB1 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-row v-show="!readOnlyMainSettings">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.id"
                  :label="$t('Settings.id')"
                  disabled
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.brokerId"
                  :label="$t('Settings.brokerId')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12" md="3">
                <v-text-field
                  v-model="item.name"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.name')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.company"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.company')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.country"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.country')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Country)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.city"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.city')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.taxiLicenceNumber"
                  :label="$t('Settings.taxiLicenceNumber')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <my-datepicker
                  v-model="item.taxiLicenceExpiration"
                  :title="$t('Settings.taxiLicenceExpiration')"
                  :label="$t('Settings.taxiLicenceExpiration')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  filled
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.type"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.type')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Type)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.status"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.status')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Status)"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.descriptionShort"
                  :label="$t('Settings.descriptionShort')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="item.description"
                  :label="$t('Settings.description')"
                  :disabled="!editMode"
                  filled
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB2 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>
              {{ $t("Settings.subheaderActivationTimeOrder") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.activeOrderBeforeSend"
                  :label="$t('Settings.activeOrderBeforeSend')"
                  :title="$t('Settings.activeOrderBeforeSendTitle')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.defaultPresendTime"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.defaultPresendTime')"
                  :title="$t('Settings.defaultPresendTimeTitle')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>
              {{ $t("Settings.subheaderDistanceAutomat") }}</v-subheader
            >
            <v-row>
              <!-- <v-col cols="12" md="3">
                <v-select
                  v-model="item.directSendType"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  label="Direct send type"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(DirectSendType)"
                  filled
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radiusDirect"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radiusDirect')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.delay"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.delay')"
                  :title="$t('Settings.delayTitle')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>
              {{ $t("Settings.subheaderPriorityAutomat") }}</v-subheader
            >
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  :disabled="!editMode"
                  :label="$t('Settings.automat')"
                  inset
                  required
                  v-model="item.automat"
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius1')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.automatPriorityTime1')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius2"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius2')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius3"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius3')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime2"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.automatPriorityTime2')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius4"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius4')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime3"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.automatPriorityTime3')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius5"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius5')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB3 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderDriverApp") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowSilence"
                  :label="$t('Settings.allowSilence')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.allowSilenceTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowStreetOrder"
                  :label="$t('Settings.allowStreetOrder')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.allowStreetOrderTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.popupTime"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.popupTime')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.popupTimeTitle')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderWaiting") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowWaiting"
                  :label="$t('Settings.allowWaiting')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.waitingAfterStartLimit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.waitingAfterStartLimit')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.waitingAfterStartLimitTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.waitingAfterLastLimit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.waitingAfterLastLimit')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.waitingAfterLastLimitTitle')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderWorkshift") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.vehicleAllocation"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.vehicleAllocation')"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(vehicleAllocation)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.approvalWorklog"
                  :label="$t('Settings.approvalWorklog')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.approvalWorklogTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  v-model="item.validateMileages"
                  label="Validácia kilometrov"
                  inset
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.validateMileagesTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>
            <v-subheader>{{ $t("Settings.subheaderPayment") }}</v-subheader>
            <v-divider light class="mb-6"></v-divider>
            <v-row>
              <!-- <v-col cols="12" md="3">
                <v-select
                  v-model="item.receiptEdit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  label="Úprava účtenky"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(ReceiptEdit)"
                  filled
                ></v-select>
              </v-col>-->
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.paymentProfileId"
                  :label="$t('Settings.paymentProfileId')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.profileId"
                  :label="$t('Settings.profileId')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.profileIdTitle')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderLocalityCheck") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.localityCheck"
                  :label="$t('Settings.localityCheck')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                  readonly
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.standCheck"
                  :label="$t('Settings.standCheck')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                  readonly
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB4 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderContacts") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.email"
                  :label="$t('Settings.email')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.emailTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.problemEmail"
                  :label="$t('Settings.problemEmail')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.problemEmailTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.problemPhone"
                  :label="$t('Settings.problemPhone')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.problemPhoneTitle')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderDispatchPhones") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone1"
                  :label="$t('Settings.phone1')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone2"
                  :label="$t('Settings.phone2')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone3"
                  :label="$t('Settings.phone3')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone4"
                  :label="$t('Settings.phone4')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone5"
                  :label="$t('Settings.phone5')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone6"
                  :label="$t('Settings.phone6')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone7"
                  :label="$t('Settings.phone7')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone8"
                  :label="$t('Settings.phone8')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone9"
                  :label="$t('Settings.phone9')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone10"
                  :label="$t('Settings.phone10')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone11"
                  :label="$t('Settings.phone11')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone12"
                  :label="$t('Settings.phone12')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB5 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>

            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderSmsGateway") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.smsGate"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.smsGate')"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(SmsGateway)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsIdentity"
                  :label="$t('Settings.smsIdentity')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsLogin"
                  :label="$t('Settings.smsLogin')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsPassword"
                  :label="$t('Settings.smsPassword')"
                  :disabled="!editMode"
                  filled
                  :append-icon="showSmsPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showSmsPassword ? 'text' : 'password'"
                  @click:append="showSmsPassword = !showSmsPassword"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderPhoneGateway") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.phoneGate"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.phoneGate')"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(PhoneGateway)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneGateLogin"
                  :label="$t('Settings.phoneGateLogin')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneGatePassword"
                  :label="$t('Settings.phoneGatePassword')"
                  :disabled="!editMode"
                  filled
                  :append-icon="showPhonePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPhonePassword ? 'text' : 'password'"
                  @click:append="showPhonePassword = !showPhonePassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderPhoneExtensions") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt1"
                  :label="$t('Settings.phoneExt1')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt2"
                  :label="$t('Settings.phoneExt2')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt3"
                  :label="$t('Settings.phoneExt3')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt4"
                  :label="$t('Settings.phoneExt4')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt5"
                  :label="$t('Settings.phoneExt5')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt6"
                  :label="$t('Settings.phoneExt6')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt7"
                  :label="$t('Settings.phoneExt7')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt8"
                  :label="$t('Settings.phoneExt8')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt9"
                  :label="$t('Settings.phoneExt9')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt10"
                  :label="$t('Settings.phoneExt10')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt11"
                  :label="$t('Settings.phoneExt11')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt12"
                  :label="$t('Settings.phoneExt12')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItem()"> {{ $t("btnReload") }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import {
  vehicleAllocation,
  Status,
  AutoSendType,
  Type,
  DirectSendType,
  PhoneClient,
  PhoneDriver,
  ReceiptEdit,
  ShowDestination,
  Chat,
  BreakType,
  SmsGateway,
  PhoneGateway,
} from "../enums/TaxiserviceEnum";
import { Country } from "../enums/CountryEnum";

export default {
  components: {},
  data() {
    return {
      tabs: null,
      //enums
      ShowDestination,
      Country,
      vehicleAllocation,
      AutoSendType,
      DirectSendType,
      Type,
      ReceiptEdit,
      Status,
      PhoneClient,
      PhoneDriver,
      Chat,
      BreakType,
      SmsGateway,
      PhoneGateway,
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      editMode: false,
      itemId: null,
      item: null,
      cachedItem: null,
      readOnlyMainSettings: true, //Pre role taxisluzby niektore polia zablokovane. Pre role taxxim sú povolené.
      disabledAllSettings: true, //Obycajny dispecer a manazer taxisluzby nemozu editovat ziadne nastavenia.

      showSmsPassword: false,
      showPhonePassword: false,

      sliderConfimationCheck: ["❌", "✔"],
      deleteItemSupportOrderTypes: {
        id: 0,
        type: "INSTANT",
        slider: 0,
      },
    };
  },

  created() {},
  methods: {
    enableEditMode(enabled) {
      this.editMode = enabled;
    },
    getItem() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/${this.itemId}`
        )
        .then((response) => {
          this.item = response.data;
          this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    reset() {
      this.enableEditMode(false);
      //this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.item = Object.assign({}, this.cachedItem);
    },

    submitForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.cachedItem = Object.assign({}, this.item);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
  },
  mounted() {
    this.itemId = this.$route.params.id;
    this.getItem();
    //Zablokovanie zakladnych poli proti editovaniu pre pouzivatelov taxisluzby. Pre taxximo poutivatelov je povolene
    let userRoles = this.$store.state.user.roleList;
    let enabledRoles = ["ADMIN", "SUPPORT"];
    this.readOnlyMainSettings =
      userRoles.some((a) => (enabledRoles.includes(a) ? false : true)) == true
        ? true
        : false;
    //Zablokovanie editovania vsetkych informacii pre obycajneho dispecera a manazera taxisluzby (DISPATCHER, MANAGER)
    let enabledRolesEdit = ["ADMIN", "SUPPORT", "OWNER"];
    this.disabledAllSettings = !userRoles.some((ai) =>
      enabledRolesEdit.includes(ai)
    );
  },
};
</script>
