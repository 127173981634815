<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("FeedbackList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-comment-quote-outline</v-icon>
    </v-toolbar>

    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :search="filter.search"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td></td>
          <td>
            <v-text-field
              v-model="filter.id"
              type="text"
              :label="$t('FeedbackList.id')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="filter.checked"
              :label="$t('FeedbackList.checked')"
              single-line
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-select>
          </td>
          <td>
            <v-text-field
              v-model="filter.createdAt"
              :label="$t('FeedbackList.createdAt')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.fromEmail"
              :label="$t('FeedbackList.fromEmail')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.message"
              :label="$t('FeedbackList.message')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small @click="detail(item.id)">fa-eye</v-icon> -->
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
        <v-icon small class="ml-2" @click="openEditDialog(item.id)"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt ? $options.filters.formatDateLong(item.createdAt) : "-"
        }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showEditDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{ $t("FeedbackList.dialogTitle") }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formEdit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('FeedbackList.id')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.id"
                    disabled
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    dense
                    :label="$t('FeedbackList.checked')"
                    filled
                    v-model="editDialogData.checked"
                  ></v-checkbox
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showEditDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitEditDialog()">{{
              $t("btnSave")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data() {
    return {
      moment: moment,
      gridItems: [],
      loadingData: false,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        id: "",
        checked: "",
        fromEmail: "",
        message: "",
      },

      //Dialogove okno s Editovanim polozky
      editDialogData: {},
      showEditDialog: false,

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  mounted() {
    this.getAll();
  },
  computed: {
    gridHeaders() {
      return [
        {
          text: this.$t("FeedbackList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("FeedbackList.tableHeaders.id"),
          value: "id",
          filter: (value) => {
            if (!this.filter.id) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.id.toLowerCase());
            }
          },
        },
        {
          text: this.$t("FeedbackList.tableHeaders.checked"), //"Vyriešené",
          value: "checked",
        },
        {
          text: this.$t("FeedbackList.tableHeaders.createdAt"),
          value: "createdAt",
          filter: (value) => {
            if (!this.filter.createdAt) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.createdAt.toLowerCase());
            }
          },
        },
        {
          text: this.$t("FeedbackList.tableHeaders.fromEmail"),
          value: "fromEmail",
          filter: (value) => {
            if (!this.filter.fromEmail) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.fromEmail.toLowerCase());
            }
          },
        },
        {
          text: this.$t("FeedbackList.tableHeaders.message"),
          value: "message",
          filter: (value) => {
            if (!this.filter.message) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.message.toLowerCase());
            }
          },
        },
      ];
    },
  },
  methods: {
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/feedback`)
        .then((response) => {
          this.loadingData = false;
          //compare validTo datetime with current datetime and set color to red if validTo is in past.
          this.gridItems = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/

    openEditDialog(id) {
      if (this.$refs.formEdit) {
        this.$refs.formEdit.resetValidation();
        /*this.$nextTick(() => {
          if (this.$refs[formEdit]) {
            this.$refs.formEdit.resetValidation();
          }
        });*/
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.editDialogData = selectedItem;
        this.showEditDialog = true;
      } else {
        console.log("viewLocalityOnDialogMap missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },

    submitEditDialog() {
      const isValid = this.$refs.formEdit.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/feedback/` +
              this.editDialogData.id +
              `?checked=` +
              this.editDialogData.checked
            //{checked: this.editDialogData.checked,}
          )
          .then((response) => {
            this.showEditDialog = false;
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },
  },
};
</script>
