<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("TaxiserviceList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fas fa-city</v-icon>
    </v-toolbar>
    <v-btn
      small
      class="my-2"
      color="success"
      :disabled="!displayOnlyForAdmin"
      @click.stop="openCreateDialog()"
    >
      {{ $t("btnCreate") }}
    </v-btn>

    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark label>
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip :color="getTypeColor(item.type)" dark label>
          {{ item.type }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="detail(item.id)">fa-eye</v-icon>
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
      </template>
    </v-data-table>

    <v-dialog
      v-model="createDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("TaxiserviceList.dialogCreateTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('TaxiserviceList.name')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.name"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="createItem.country"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :label="$t('TaxiserviceList.country')"
                    :items="Object.keys(Country)"
                    required
                    filled
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :label="$t('TaxiserviceList.city')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.city"
                    required
                  >
                    ></v-text-field
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="createDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import { Status, AutoSendType, Type } from "../enums/TaxiserviceEnum";
import { Country } from "../enums/CountryEnum";

export default {
  data() {
    return {
      Status,
      Country,
      AutoSendType,
      Type,

      moment: moment,
      gridHeaders: [
        {
          text: this.$t("TaxiserviceList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        { text: this.$t("TaxiserviceList.tableHeaders.id"), value: "id" },
        { text: this.$t("TaxiserviceList.tableHeaders.name"), value: "name" },
        {
          text: this.$t("TaxiserviceList.tableHeaders.status"),
          value: "status",
        },
        { text: this.$t("TaxiserviceList.tableHeaders.type"), value: "type" },
        { text: this.$t("TaxiserviceList.tableHeaders.city"), value: "city" },
        {
          text: this.$t("TaxiserviceList.tableHeaders.country"),
          value: "country",
        },
      ],
      gridItems: [],

      loadingData: false,
      displayOnlyForAdmin: false,
      createDefaultItem: {
        name: "",
        country: Country.SLOVAKIA,
        city: "",
      },
      createItem: {},

      createDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      checkSettings: null,
      checkModules: null,
      checkOrderTypes: null,
      checkPaymentOptions: null,
      checkLocalityPickup: null,
      checkStands: null,
      checkLocalityPrice: null,
    };
  },

  mounted() {
    this.getAll();
    this.getUserRole();
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
          this.snackbar.timeout = 4000;
        });
    },
    detail(id) {
      this.$router.push({ name: "taxiservice-detail", params: { id: id } });
    },

    openCreateDialog() {
      // this.$refs.formCreate.validate();
      // this.$refs.formCreate.resetValidation();
      this.createItem = Object.assign({}, this.createDefaultItem);
      this.createDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice`,
            this.createItem
          )
          .then((response) => {
            this.createDialog = false;
            this.detail(response.data.id);
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },
    checkSetup(id) {
      //taxiservice setting
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice/${id}`
        )
        .then((response) => {
          this.checkSettings = Object.assign({}, response.data);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
      //Modules
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice/module`
        )
        .then((response) => {
          if (response.data) {
            this.checkModules = Object.assign({}, response.data);
          } else {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("Login.msgModuleError");
            this.snackbar.color = "error";
            alert(this.$t("Login.msgModuleError"));
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Login.msgModuleError");
          this.snackbar.color = "error";
          //Zobrazenie alertu, lebo snakbar sa pri prechod na inu stranku rychlo strati
          alert(this.$t("Login.msgModuleError"));
        });

      //order types
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/support-type-order`
        )
        .then((response) => {
          this.checkOrderTypes = Object.assign({}, response.data);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });

      //Payment Option List
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/payment/option`)
        .then((response) => {
          //this.checkPaymentOptions = JSON.parse(JSON.stringify(response.data));
          this.checkPaymentOptions = Object.assign({}, response.data);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });

      //Pickup locality
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-pickup`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList) {
            if (itemList.constructor.name != "Array") {
              var newItemList = [];
              newItemList.push(itemList);
              itemList = newItemList;
            }
          }
          this.checkLocalityPickup = itemList;
        })
        .catch((e) => {});

      // Stand
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          this.checkStands = itemList;
        })
        .catch((e) => {});

      //Locality Price
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-price`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          this.checkLocalityPrice = itemList;
        })
        .catch((e) => {});
    },

    getStatusColor(status) {
      switch (status) {
        case Status.ACTIVE:
          return "green";
        case Status.INACTIVE:
          return "red";
        case Status.HIDDEN:
          return "blue";
        default:
          return "grey";
      }
    },
    getTypeColor(type) {
      switch (type) {
        case Type.FULL:
          return "green";
        case Type.DIRECT:
          return "blue";
        case Type.DRINK:
          return "yellow";
        case Type.CALL:
          return "purple";
        case Type.SMS:
          return "cyan";
        default:
          return "grey";
      }
    },
    getAutoSendTypeColor(type) {
      switch (type) {
        case AutoSendType.DISTANCE:
          return "purple";
        case AutoSendType.UNOCCUPIED:
          return "green";
        case AutoSendType.RADIUS:
          return "yellow";
        case AutoSendType.NEAR:
          return "blue";
        case AutoSendType.PRIORITY:
          return "red";
        default:
          return "grey";
      }
    },
  },
};
</script>
