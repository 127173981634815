<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("messageDispatchList.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-bell-cog-outline</v-icon>
    </v-toolbar>

    <v-btn small class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="5">
        <date-range-picker filled v-model="dateRange" eager>
        </date-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" large @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :search="filter.search"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td></td>
          <td>
            <!--<v-text-field
              v-model="filter.id"
              :label="$t('messageDispatchList.filterLabel.Id')"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>-->
          </td>
          <td></td>
          <td>
            <v-text-field
              v-model="filter.taxiserviceName"
              type="text"
              :label="$t('messageDispatchList.filterLabel.Taxiservice')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="filter.status"
              :label="$t('messageDispatchList.filterLabel.Status')"
              :items="Object.values($t('messageDispatchStatusArray'))"
              single-line
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="filter.type"
              :label="$t('messageDispatchList.filterLabel.Type')"
              :items="Object.values($t('messageDispatchTypeArray'))"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-select>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <span>{{ moment(item.validFrom).format("DD.MM.YY HH:mm") }}</span>
      </template>
      <template v-slot:[`item.validTo`]="{ item }">
        <span>{{ moment(item.validTo).format("DD.MM.YY HH:mm") }}</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip :color="getTypeColor(item.type)" dark label>
          {{ $t("messageDispatchType." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark label>
          {{ $t("messageDispatchStatus." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <span :title="item.message">{{ item.title }}</span>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small @click="detail(item.id)">fa-eye</v-icon> -->
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
        <v-icon small class="ml-2" @click="openEditDialog(item.id)"
          >mdi-square-edit-outline</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog
      v-model="showCreateDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("messageDispatchList.showCreateDialog")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('messageDispatchList.Taxiservice')"
                    v-model="createDialogData.item.taxiserviceId"
                    :items="taxiserviceList"
                    item-text="name"
                    item-value="id"
                    filled
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('messageDispatchList.Type')"
                    filled
                    :items="$t('messageDispatchTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.type"
                    required
                    @change="onTypeChange()"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    :label="$t('messageDispatchList.ValidFrom')"
                    v-model="createDialogData.item.validFrom"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>

                <v-col cols="12" md="6">
                  <my-datepicker
                    :label="$t('messageDispatchList.ValidTo')"
                    v-model="createDialogData.item.validTo"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('messageDispatchList.Title')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.title"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    dense
                    :label="$t('messageDispatchList.Message')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.message"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showCreateDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEditDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("messageDispatchList.showEditDialog")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formEdit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('messageDispatchList.TaxiserviceId')"
                    v-model="editDialogData.taxiserviceId"
                    :items="taxiserviceList"
                    item-text="name"
                    item-value="id"
                    filled
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('messageDispatchList.Type')"
                    filled
                    :items="$t('messageDispatchTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.type"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    :label="$t('messageDispatchList.ValidFrom')"
                    v-model="editDialogData.validFrom"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>

                <v-col cols="12" md="6">
                  <my-datepicker
                    :label="$t('messageDispatchList.ValidTo')"
                    v-model="editDialogData.validTo"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    dense
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="setValidToNow()"
                    title="Set ValidTo to now"
                    dense
                  >
                    <v-icon dark>mdi-timer-stop-outline</v-icon
                    ><v-spacer></v-spacer>
                    {{ $t("messageDispatchList.btnSetActualDate") }}
                  </v-btn></v-col
                >
                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('messageDispatchList.Title')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.title"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    dense
                    :label="$t('messageDispatchList.Message')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.message"
                    required
                  ></v-textarea
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showEditDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitEditDialog()">{{
              $t("btnSave")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import {
  TypeMessageDispatchColor,
  StatusMessageDispatchColor,
} from "../enums/MessageDispatchEnum";

export default {
  data() {
    return {
      TypeMessageDispatchColor,
      StatusMessageDispatchColor,
      taxiserviceList: [],

      moment: moment,

      gridItems: [],

      loadingData: false,
      //Dialogove okno s Vytvaranim novej polozky
      dateRange: [], //["2021-07-22","2021-07-24"],

      createDefaultItem: {
        taxiserviceId: null,
        type: "",
        validFrom: new Date(),
        validTo: new Date(),
        title: "",
        message: "",
      },

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        taxiserviceId: "",
        taxiserviceName: "",
        type: "",
        validFrom: "",
        validTo: "",
        title: "",
        message: "",
      },
      createDialogData: {
        item: {},
      },

      showCreateDialog: false,

      //Dialogove okno s Editovanim polozky
      editDialogData: {},
      showEditDialog: false,

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  mounted() {
    this.getTaxiserviceList();
    //zoznam Oznameni nacitat po nacitani zoznamu taxisluzieb. Funkcu getAll() volat z getTaxiserviceList()
  },
  computed: {
    gridHeaders() {
      return [
        {
          text: this.$t("messageDispatchList.toolbarActions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("messageDispatchList.Id"),
          value: "id",
          filter: (value) => {
            if (!this.filter.id) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.id.toLowerCase());
            }
          },
        },
        {
          text: "txID",
          value: "taxiserviceId",
        },
        {
          text: this.$t("messageDispatchList.Taxiservice"),
          value: "taxiserviceName",
          filter: (value) => {
            if (!this.filter.taxiserviceName) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.taxiserviceName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("messageDispatchList.Status"),
          value: "status",
          filter: (value) => {
            if (!this.filter.status) {
              return true;
            }
            return this.$t("messageDispatchStatusArray." + value).includes(
              this.filter.status
            );
          },
        },
        {
          text: this.$t("messageDispatchList.Type"),
          value: "type",
          filter: (value) => {
            if (!this.filter.type) {
              return true;
            }
            return this.$t("orderType." + value)
              .toLowerCase()
              .includes(this.filter.type.toLowerCase());
          },
        },
        { text: this.$t("messageDispatchList.ValidFrom"), value: "validFrom" },
        { text: this.$t("messageDispatchList.ValidTo"), value: "validTo" },
        { text: this.$t("messageDispatchList.Title"), value: "title" },
      ];
    },
  },
  methods: {
    getTaxiserviceList() {
      var nullTaxiservice = {
        name: "Všetky taxislužby",
        id: null,
      };
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/picker-available`
        )
        .then((response) => {
          this.taxiserviceList = response.data.available;
          this.taxiserviceList.unshift(nullTaxiservice);

          //Niekedy pri prvom nacitani stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
          if (this.dateRange[0] == undefined) {
            setTimeout(() => {
              this.getAll();
            }, 2000);
          } else {
            this.getAll();
          }
          console.log("taxiserviceList", this.taxiserviceList);
        })
        .catch((e) => {
          this.loadingDataForPicker = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "messageDispatchList.msgGetTaxiservicListForPickerError"
          );
          this.snackbar.color = "error";
          this.taxiserviceList.push(nullTaxiservice);
        });
    },

    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/message-dispatch/manage?from=${this.dateRange[0]}T00:00:00.716Z&to=${this.dateRange[1]}T23:59:59.716Z`
        )
        .then((response) => {
          this.loadingData = false;
          //compare validTo datetime with current datetime and set color to red if validTo is in past.
          response.data.forEach((item) => {
            //console.log(item.validTo);
            //console.log(moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
            //console.log(new Date());
            if (moment(item.validTo).isBefore(moment())) {
              item.color = "red";
              item.status = "EXPIRED";
            } else if (moment(item.validFrom).isAfter(moment())) {
              item.color = "blue";
              item.status = "PLANNED";
            } else {
              item.color = "green";
              item.status = "ACTIVE";
            }
            /*if (item.validTo < new Date()) {
              item.color = "red";
              item.status = "EXPIRED";
            } else {
              item.color = "green";
              item.status = "ACTIVE";
            }*/
            //Add taxiservice name to item by taxiserviceId from this.taxiserviceList
            this.taxiserviceList.forEach((taxiservice) => {
              if (taxiservice.id == item.taxiserviceId) {
                item.taxiserviceName = taxiservice.name;
              }
            });
          });
          this.gridItems = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/

    openCreateDialog() {
      this.createDialogData.item = Object.assign({}, this.createDefaultItem);
      this.showCreateDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      //Nechceme aby niekomu ostali vysiet stare spravy. Rozumny cas je max 60 dni
      //check if createDialogData.item.validTo is before next 60 days from now.
      if (
        this.createDialogData.item.validTo >
        new Date(new Date().setDate(new Date().getDate() + 60))
      ) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("messageDispatchList.msgValidMaxDate");
        this.snackbar.color = "error";
        return;
      }
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/message-dispatch`,
            this.createDialogData.item
          )
          .then((response) => {
            this.showCreateDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },
    openEditDialog(id) {
      if (this.$refs.formEdit) {
        this.$refs.formEdit.resetValidation();
        /*this.$nextTick(() => {
          if (this.$refs[formEdit]) {
            this.$refs.formEdit.resetValidation();
          }
        });*/
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.editDialogData = selectedItem;
        this.showEditDialog = true;
      } else {
        console.log("viewLocalityOnDialogMap missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },

    submitEditDialog() {
      const isValid = this.$refs.formEdit.validate();
      //Nechceme aby niekomu ostali vysiet stare spravy. Rozumny cas je max 60 dni
      //check if editDialogData.validTo is before next 60 days from now.
      if (
        this.editDialogData.validTo >
        new Date(new Date().setDate(new Date().getDate() + 60))
      ) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("messageDispatchList.msgValidMaxDate");
        this.snackbar.color = "error";
        return;
      }
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/message-dispatch`,
            this.editDialogData
          )
          .then((response) => {
            this.showEditDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },
    setValidToNow() {
      this.editDialogData.validTo = new Date();
    },
    onTypeChange() {
      console.log("onTypeChange", this.createDialogData.item.type);
      var date = new Date();
      if (this.createDialogData.item.type == "CONTRACT") {
        // add 1 day
        date.setDate(date.getDate() + 14);
      } else if (this.createDialogData.item.type == "MAINTENANCE") {
        // add 1 year
        date.setDate(date.getDate() + 5);
      } else if (this.createDialogData.item.type == "PAYMENT") {
        // add 1 year
        date.setDate(date.getDate() + 7);
      } else if (this.createDialogData.item.type == "RELEASE") {
        // add 1 year
        date.setDate(date.getDate() + 21);
      } else if (this.createDialogData.item.type == "SYSTEM_STATUS") {
        // add 1 year
        date.setDate(date.getDate() + 1);
      } else if (this.createDialogData.item.type == "OTHER") {
        // add 1 year
        date.setDate(date.getDate() + 2);
      }
      this.createDialogData.item.validTo = date;
    },

    getStatusColor(status) {
      return this.StatusMessageDispatchColor[status];
    },
    getTypeColor(type) {
      return this.TypeMessageDispatchColor[type];
    },
  },
};
</script>
