<template>
  <div>
    <div style="display: flex" class="my-2">
      <v-btn
        small
        color="warning"
        :loading="loadingGeneratePrices"
        @click.stop="generatePricesDialog = true"
        :disabled="pricelistType == 'SIMPLE'"
      >
        {{ $t("Prices.btnGenerate") }}
      </v-btn>
    </div>
    <div
      v-if="editModeGrid"
      style="display: flex; align-items: center"
      class="my-2"
    >
      <v-btn
        small
        color="success"
        :loading="loadingUpdateItems"
        @click.stop="updateItems()"
      >
        {{ $t("btnSave") }}
      </v-btn>
      <v-btn small class="ml-2" color="error" @click.stop="closeEdit()">
        {{ $t("btnCancel") }}
      </v-btn>
    </div>
    <div v-else style="display: flex; align-items: center" class="my-2">
      <v-btn small color="info" @click.stop="editModeGrid = !editModeGrid">
        {{ $t("btnEdit") }}
      </v-btn>
      <!-- checkbox to display only active items -->
      <v-switch
        class="pl-8 mt-0"
        :label="$t('Prices.activeOnlySwitch')"
        v-model="activeOnly"
        @change="getAll()"
        dense
        hide-details
      ></v-switch>
      <v-spacer></v-spacer>
    </div>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      sort-by.sync="name"
      sort-desc.sync="true"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span v-if="editModeGrid">{{ item.name }}</span>
        <!-- <v-text-field
          v-model="item.name"
          :hide-details="true"
          dense
          single-line
          v-if="editModeGrid"
          readonly
        ></v-text-field> -->
        <span v-else>{{ item.name }}</span>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.fareDriveType`]="{ item }">
        <!-- :items="Object.values(FareDriveType)" -->
        <v-select
          :items="$t('PricesEnums.FareDriveTypeArray')"
          :hide-details="true"
          dense
          v-model="item.fareDriveType"
          single-line
          v-if="editModeGrid"
        ></v-select>
        <span
          v-else
          :title="$t('PricesEnums.FareDriveType.' + item.fareDriveType)"
          ><v-icon
            v-if="item.fareDriveType == 'FIXED'"
            color="blue"
            small
            class="mr-1"
            >mdi-flag-triangle</v-icon
          >
          <v-icon
            v-else-if="item.fareDriveType == 'DISTANCE'"
            color="primary"
            small
            class="mr-1"
            >mdi-map-marker-distance</v-icon
          >
          {{ $t("PricesEnums.FareDriveTypeShort." + item.fareDriveType) }}</span
        >
      </template>

      <template v-slot:[`item.fareStartType`]="{ item }">
        <!--Cena vyzdvihnutia s vypoctom za trasu od/k stanovistu je iba pre pricelistType "EXTENDED"-->
        <!-- :items="pricelistType == 'SIMPLE' || pricelistType == 'LOCALITY'? Object.values(FareStartType).filter((x) => x == 'FIXED'): Object.values(FareStartType)" -->
        <!-- v items odfiltruj z $t('PricesEnums.FareStartTypeArray') všetky položky okrem "FIXED" -->
        <v-select
          :items="
            pricelistType == 'SIMPLE' || pricelistType == 'LOCALITY'
              ? $t('PricesEnums.FareStartTypeArray').filter(
                  (x) => x.value == 'FIXED'
                )
              : $t('PricesEnums.FareStartTypeArray')
          "
          :hide-details="true"
          dense
          v-model="item.fareStartType"
          single-line
          v-if="editModeGrid"
        ></v-select>
        <span
          v-else
          :title="$t('PricesEnums.FareStartType.' + item.fareStartType)"
        >
          <v-icon
            v-if="item.fareStartType == 'FIXED'"
            color="blue"
            small
            class="mr-1"
            >mdi-flag-triangle</v-icon
          >
          <v-icon
            v-else-if="
              item.fareStartType == 'FROM_STAND' ||
              item.fareStartType == 'TO_STAND' ||
              item.fareStartType == 'BETWEEN_STAND'
            "
            color="primary"
            small
            class="mr-1"
            >mdi-map-marker-distance</v-icon
          >
          {{ $t("PricesEnums.FareStartTypeShort." + item.fareStartType) }}</span
        >
      </template>

      <template v-slot:[`item.fareDriveDistance`]="{ item }">
        <v-text-field
          v-model="item.fareDriveDistance"
          :hide-details="true"
          dense
          single-line
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareDriveDistance }}</span>
      </template>

      <template v-slot:[`item.fareDriveTime`]="{ item }">
        <v-text-field
          v-model="item.fareDriveTime"
          :hide-details="true"
          dense
          single-line
          required
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareDriveTime }}</span>
      </template>

      <template v-slot:[`item.fareStart`]="{ item }">
        <v-text-field
          v-model="item.fareStart"
          :hide-details="true"
          dense
          single-line
          required
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareStart }}</span>
      </template>

      <template v-slot:[`item.fareWaiting`]="{ item }">
        <v-text-field
          v-model="item.fareWaiting"
          :hide-details="true"
          dense
          single-line
          required
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareWaiting }}</span>
      </template>

      <template v-slot:[`item.fareWaitingLimit`]="{ item }">
        <v-text-field
          v-model="item.fareWaitingLimit"
          :hide-details="true"
          dense
          single-line
          required
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareWaitingLimit }}</span>
      </template>

      <template v-slot:[`item.fareJourneyMinimum`]="{ item }">
        <v-text-field
          v-model="item.fareJourneyMinimum"
          :hide-details="true"
          dense
          single-line
          required
          type="number"
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.fareJourneyMinimum }}</span>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <v-text-field
          v-model="item.note"
          :hide-details="true"
          dense
          single-line
          required
          v-if="editModeGrid"
        ></v-text-field>
        <span v-else>{{ item.note }}</span>
      </template>

      <template v-slot:[`item.localityPriceFromId`]="{ item }">
        <span>{{ item.localityPriceFromId }}</span>
      </template>
      <template v-slot:[`item.localityPriceToId`]="{ item }">
        <span>{{ item.localityPriceToId }}</span>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="editModeGrid" style="display: flex">
          <v-icon small color="green" @click="save"> fa-save </v-icon>
          <v-icon class="ml-3" small color="red" @click="closeEdit()">
            fa-times
          </v-icon>
        </div>
        <div v-else style="display: flex">
          <v-icon color="green" small @click="editItem(item)"> fa-pen </v-icon>
        </div>
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
      </template>
    </v-data-table>

    <!-- Dialog na aktualizovanie ceny objednavky-->
    <v-dialog v-model="generatePricesDialog" max-width="600px">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("Prices.generatePricesDialogTitle") }}
        </v-card-title>
        <v-card-text>
          {{ $t("Prices.generatePricesDialogText") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(generatePricesDialog = false), generatePrices()"
          >
            {{ $t("Prices.btnRegenerate") }}
          </v-btn>
          <v-btn color="error" text @click="generatePricesDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { FareStartType, FareDriveType } from "../enums/PricelistEnum";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  name: "Prices",
  props: {
    pricelistId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      FareStartType,
      FareDriveType,

      //zobrazenie vybranych objektov iba pre admina
      displayOnlyForAdmin: false,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      pricelistType: "SIMPLE",

      activeOnly: false,

      gridHeaders: [
        {
          text: this.$t("Prices.tableHeaders.name"),
          value: "name",
          width: "20%",
        },
        { text: this.$t("Prices.tableHeaders.active"), value: "active" },
        {
          text: this.$t("Prices.tableHeaders.fareStartType"),
          value: "fareStartType",
        },
        { text: this.$t("Prices.tableHeaders.fareStart"), value: "fareStart" },
        {
          text: this.$t("Prices.tableHeaders.fareWaitingLimit"),
          value: "fareWaitingLimit",
        },
        {
          text: this.$t("Prices.tableHeaders.fareWaiting"),
          value: "fareWaiting",
        },
        {
          text: this.$t("Prices.tableHeaders.fareDriveType"),
          value: "fareDriveType",
        },
        {
          text: this.$t("Prices.tableHeaders.fareDriveDistance"),
          value: "fareDriveDistance",
        },
        {
          text: this.$t("Prices.tableHeaders.fareDriveTime"),
          value: "fareDriveTime",
        },
        {
          text: this.$t("Prices.tableHeaders.fareJourneyMinimum"),
          value: "fareJourneyMinimum",
        },
        { text: this.$t("Prices.tableHeaders.note"), value: "note" },
        {
          text: this.$t("Prices.tableHeaders.localityPriceFromId"),
          value: "localityPriceFromId",
        },
        {
          text: this.$t("Prices.tableHeaders.localityPriceToId"),
          value: "localityPriceToId",
        },
      ],
      gridItems: [],
      gridItemsClone: [],

      generatePricesDialog: false,

      loadingData: false,
      loadingGeneratePrices: false,
      loadingUpdateItems: false,

      editModeGrid: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
      this.pricelistType = modules.pricelistType;
    }
  },

  mounted() {
    this.getUserRole();
    this.getAll();
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },

    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/pricelist/prices/${this.pricelistId}`
        )
        .then((response) => {
          this.loadingData = false;
          //ID zobrazovať iba adminovi
          if (this.displayOnlyForAdmin == false) {
            this.gridHeaders = this.gridHeaders.filter((item) => {
              return (
                item.value != "localityPriceFromId" &&
                item.value != "localityPriceToId"
              );
            });
          }
          //console.log("this.gridHeaders", this.gridHeaders);
          this.gridItems = response.data;
          this.gridItemsClone = JSON.parse(JSON.stringify(this.gridItems));
          //display only active items
          if (this.activeOnly) {
            this.gridItems = this.gridItems.filter((x) => x.active);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    generatePrices() {
      this.loadingData = true;
      this.loadingGeneratePrices = true;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/pricelist/generate-price/${this.pricelistId}`
        )
        .then((response) => {
          this.loadingGeneratePrices = false;
          this.getAll();
          //snackbar
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Prices.msgGeneratePricesSuccess");
          this.snackbar.color = "success";
        })
        .catch((e) => {
          console.log(e);
          this.loadingData = false;
          this.loadingGeneratePrices = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Prices.msgGeneratePricesError");
          this.snackbar.color = "error";
        });
    },
    closeEdit() {
      this.gridItems = JSON.parse(JSON.stringify(this.gridItemsClone));
      this.editModeGrid = false;
    },
    updateItems() {
      this.loadingUpdateItems = true;
      this.loadingData = true;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/pricelist/prices`,
          this.gridItems
        )
        .then((response) => {
          this.loadingUpdateItems = false;
          this.editModeGrid = false;
          this.getAll();
          //snackbar
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Prices.msgUpdatePricesSuccess");
          this.snackbar.color = "success";
        })
        .catch((e) => {
          console.log(e);
          this.loadingUpdateItems = false;
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Prices.msgUpdatePricesError");
          this.snackbar.color = "error";
        });
    },
  },
};
</script>
