<template>
  <div>
    <v-btn class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :items-per-page="40"
      sort-by.sync="type"
      sort-desc.sync="true"
      class="elevation-1"
    >
      <template v-slot:[`item.enabled`]="{ item }">
        <v-simple-checkbox v-model="item.enabled" disabled></v-simple-checkbox>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="openEditDialog(item)">
          mdi-square-edit-outline
        </v-icon>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip dark label color="secondary">
          {{ $t("smsTemplateType." + item.type) }}
        </v-chip>
      </template>
      <template v-slot:[`item.language`]="{ item }">
        <v-chip dark label>
          {{ $t("smsTemplateLanguage." + item.language) }}
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("SmsTemplateList.dialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('SmsTemplateList.type')"
                    filled
                    v-model="editItem.type"
                    :items="$t('smsTemplateTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :disabled="typeFieldDisabled"
                    required
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('SmsTemplateList.language')"
                    filled
                    v-model="editItem.language"
                    :items="$t('smsTemplateLanguageArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.enabled"
                    :label="$t('SmsTemplateList.enabled')"
                    required
                    filled
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.blockPaymentInvoice"
                    :label="$t('SmsTemplateList.blockPaymentInvoice')"
                    required
                    filled
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn color="primary" @click="addExampleTemplate()">{{
                    $t("SmsTemplateList.templateExample")
                  }}</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('SmsTemplateList.template')"
                    filled
                    v-model="editItem.template"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <div>
                    <div>
                      <b
                        >{{ $t("SmsTemplateList.dialogText1") }} <br />{{
                          $t("SmsTemplateList.dialogText2")
                        }}</b
                      >
                      <br />
                    </div>
                    <span v-if="editItem.type != 'VZDY'"
                      >{{ $t("SmsTemplateList.VAR_clientPhone")
                      }}<v-chip
                        v-if="editItem.type != 'VZDY'"
                        small
                        class="ma-1"
                      >
                        %VAR_clientPhone
                      </v-chip></span
                    >
                    <!-- <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_driverNickname")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_driverNickname
                      </v-chip></span
                    > -->
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_driverFirstName")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_driverFirstName
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_driverLastName")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_driverLastName
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_driverPhone")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_driverPhone
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_vehicleBrand")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_vehicleBrand
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_vehicleModel")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_vehicleModel
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_vehicleLpn")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_vehicleLpn
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_vehicleColor")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_vehicleColor
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'VZDY'"
                      ><br />{{ $t("SmsTemplateList.VAR_requestedTime")
                      }}<v-chip
                        v-if="editItem.type != 'VZDY'"
                        small
                        class="ma-1"
                      >
                        %VAR_requestedTime
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_currency")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_currency
                      </v-chip></span
                    >
                    <span v-if="editItem.type != 'CANCELLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_estimatedPriceTotal")
                      }}<v-chip
                        v-if="editItem.type != 'CANCELLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_estimatedPriceTotal
                      </v-chip></span
                    >
                    <span v-if="editItem.type == 'SETTLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_distance")
                      }}<v-chip
                        v-if="editItem.type == 'SETTLED'"
                        small
                        class="ma-1"
                      >
                        %VAR_distance
                      </v-chip></span
                    >
                    <span v-if="editItem.type == 'SETTLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_finalPriceTotal")
                      }}<v-chip small class="ma-1"> %VAR_finalPriceTotal </v-chip></span
                    >
                    <span v-if="editItem.type == 'SETTLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_finalPriceExtra")
                      }}<v-chip small class="ma-1"> %VAR_finalPriceExtra </v-chip></span
                    >
                    <span v-if="editItem.type == 'SETTLED'"
                      ><br />{{ $t("SmsTemplateList.VAR_finalPriceDiscount")
                      }}<v-chip small class="ma-1"> %VAR_finalPriceDiscount </v-chip></span
                    >
                    <span
                      v-if="
                        editItem.type != 'CANCELLED' &&
                        editItem.type != 'SETTLED'
                      "
                      ><br />{{ $t("SmsTemplateList.VAR_sms_url_id_short")
                      }}<v-chip
                        v-if="
                          editItem.type != 'CANCELLED' &&
                          editItem.type != 'SETTLED'
                        "
                        small
                        class="ma-1"
                      >
                        https://oi.taxximo.com?i=%VAR_idShort
                      </v-chip></span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn
              color="success"
              :loading="loadingSaveItem"
              text
              @click.stop="submitEditDialog()"
              >{{ $t("btnSave") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SmsTemplateList",
  data() {
    return {
      gridHeaders: [
        {
          text: this.$t("SmsTemplateList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("SmsTemplateList.tableHeaders.enabled"),
          value: "enabled",
        },
        { text: this.$t("SmsTemplateList.tableHeaders.type"), value: "type" },
        {
          text: this.$t("SmsTemplateList.tableHeaders.language"),
          value: "language",
        },
        {
          text: this.$t("SmsTemplateList.tableHeaders.template"),
          value: "template",
        },
      ],
      gridItems: [],

      loadingData: false,
      loadingSaveItem: false,

      editDefaultItem: {
        //id: null,
        enabled: false,
        language: "SK",
        type: null,
        template: null,
        blockPaymentInvoice: false,
      },
      editItem: {},
      editDialog: false,
      typeFieldDisabled: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      /*
        %VAR_currency
        private UUID id;
        private String currency;
        private String displayPrice;
        private Double distance;
        private BigDecimal estimatedPrice;
        private Double priceDrive;
        private Double price;
        private ZonedDateTime requestedTime;

        private String clientPhone;

        private String driverNickname;
        private String driverFirstName;
        private String driverLastName;
        private String driverPhone;

        private String vehicleLpn;
        private VehicleEnum.Color vehicleColor;
        private String vehicleBrand;
        private String vehicleModel;
      */
    };
  },

  created() {},

  mounted() {
    this.getAll();
  },

  methods: {
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/sms/template`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    openCreateDialog() {
      this.editItem = Object.assign({}, this.editDefaultItem);
      this.typeFieldDisabled = false;
      this.editDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    openEditDialog(item) {
      this.editItem = Object.assign({}, item);
      this.typeFieldDisabled = true;
      this.editDialog = true;
    },

    submitEditDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (this.typeFieldDisabled == false && this.editItem.type == null) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("SmsTemplateList.msgErrorSelectType");
        this.snackbar.color = "error";
        return;
      }
      //check if this.editItem.type is already in gridItems
      if (this.typeFieldDisabled == false) {
        for (let i = 0; i < this.gridItems.length; i++) {
          if (this.gridItems[i].type == this.editItem.type) {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "SmsTemplateList.msgErrorAlreadyUsedType"
            );
            this.snackbar.color = "error";
            return;
          }
        }
      }
      if (isValid) {
        this.loadingSaveItem = true;
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/sms/template`,
            this.editItem
          )
          .then((response) => {
            this.editDialog = false;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";

            this.loadingSaveItem = false;
            this.getAll();
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";

            this.loadingSaveItem = false;
          });
      }
    },
    addExampleTemplate() {
      //add example template if type is selected
      if (this.editItem.type != null) {
        switch (this.editItem.type) {
          case "ACCEPTED":
            this.editItem.template =
              this.$t("SmsTemplateList.exampleAccepted1") +
              "%VAR_vehicleLpn (%VAR_vehicleBrand %VAR_vehicleModel)" +
              this.$t("SmsTemplateList.exampleAccepted2");
            break;
          case "APPROACHING":
            this.editItem.template =
              this.$t("SmsTemplateList.exammpleApproaching1") +
              "(%VAR_vehicleBrand %VAR_vehicleModel)" +
              this.$t("SmsTemplateList.exammpleApproaching2");
            break;
          case "WAITING":
            this.editItem.template =
              this.$t("SmsTemplateList.exampleWaiting1") +
              "(%VAR_vehicleBrand %VAR_vehicleModel)" +
              this.$t("SmsTemplateList.exampleWaiting2");
            break;
          case "WAITING_APPROACHING":
            this.editItem.template =
              this.$t("SmsTemplateList.exampleWaitingApproaching1") +
              "(%VAR_vehicleBrand %VAR_vehicleModel)" +
              this.$t("SmsTemplateList.exampleWaitingApproaching2");
            break;
          case "FINISHED":
            this.editItem.template = this.$t(
              "SmsTemplateList.exampleFinished1"
            );
            break;
          case "SETTLED":
            this.editItem.template =
              this.$t("SmsTemplateList.exampleSettled1") +
              "%VAR_finalPriceTotal %VAR_currency" +
              this.$t("SmsTemplateList.exampleSettled2");
            break;
          case "CANCELLED":
            this.editItem.template = this.$t(
              "SmsTemplateList.exampleCancelled1"
            );
            break;
          default:
            break;
        }
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("Settings.msgErrorSelectType");
        this.snackbar.color = "error";
      }
    },
  },
};
</script>
