export const TypeMessageDispatchColor = Object.freeze({
    CONTRACT: "blue",
    MAINTENANCE: "orange",
    PAYMENT: "purple",
    RELEASE: "teal",
    SYSTEM_STATUS: "red",
    OTHER: "grey",
});

export const StatusMessageDispatchColor = Object.freeze({
    EXPIRED: "red",
    PLANNED: "blue",
    ACTIVE: "green",
});