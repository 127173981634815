export const ObligatoryType = Object.freeze({
  //PICKUP_PRICE: "PICKUP_PRICE",
  PRICE_WAITING: "PRICE_WAITING",
  PRICE_JOURNEY: "PRICE_JOURNEY"
  //FARE_TIME_PRICE: "FARE_TIME_PRICE",
  //FARE_DISTANCE_PRICE: "FARE_DISTANCE_PRICE",
});

export const Type = Object.freeze({
  NONE: "NONE", 	// Nezadaný (0)
  GENERAL: "GENERAL", 	// Všeobecný poplatok (1)
  TYPE_INSTANT: "TYPE_INSTANT",
  TYPE_TIME: "TYPE_TIME",
  TYPE_STREET: "TYPE_STREET",
  //TYPE_DIRECT: "TYPE_DIRECT",
  //TYPE_REPEATED: "TYPE_REPEATED",
  //TYPE_AIRPORT: "TYPE_AIRPORT",
  WAITING: "WAITING", 	// Poplatok za čakanie (2)
  PREORDER: "PREORDER", 	// Objednávka na čas (3)
  NIGHT: "NIGHT", 	// Nočný príplatok (4)
  WEEKEND: "WEEKEND", 	// Víkendový príplatok (5)
  OFF_TIME: "OFF_TIME", 	// Nočný/víkendový príplatok (6)
  AIRPORT_TRANSPORT: "AIRPORT_TRANSPORT", 	// Letiskový príplatok (7)
  STATION_TRANSPORT: "STATION_TRANSPORT", 	// Staničný príplatok (8)
  ABROAD_TRANSPORT: "ABROAD_TRANSPORT", 	// Zahraničný príplatok (9)
  PICKUP: "PICKUP", 	// Poplatok za vyzdvihnutie 
  PICKUP_CITY: "PICKUP_CITY", 	// Vyzdvihnutie v meste (10)
  PICKUP_OUT_CITY: "PICKUP_OUT_CITY", 	// Vyzdvihnutie mimo mesta (11)
  DRINK_TRANSPORT: "DRINK_TRANSPORT", 	// Príplatok za prepravu vozidla zákazníka
  LUGGAGE_SMALL: "LUGGAGE_SMALL", 	// Malá batožina (12)
  LUGGAGE_MEDIUM: "LUGGAGE_MEDIUM", 	// Stredná batožina (12)
  LUGGAGE_BIG: "LUGGAGE_BIG", 	// Veľká batožina (12)
  PAYMENT_TERMINAL: "PAYMENT_TERMINAL", 	// Platobný terminál vo vozidle
  SPECIAL_TRANSPORT_OLD: "SPECIAL_TRANSPORT_OLD", 	// Preprava starších ľudí
  SPECIAL_TRANSPORT_CHAIR: "SPECIAL_TRANSPORT_CHAIR", 	// Preprava ľudí na vozíčku 
  AIRCONDITION: "AIRCONDITION", 	// Klimatizácia (14)
  BOOSTER: "BOOSTER", 	// Detský podsedák (15)
  SEAT: "SEAT", 	// Detská sedačka (16)
  ANIMAL_SMALL: "ANIMAL_SMALL", 	// Preprava zvieraťa (17)
  ANIMAL_MEDIUM: "ANIMAL_MEDIUM", 	// Preprava zvieraťa (17)
  ANIMAL_BIG: "ANIMAL_BIG", 	// Preprava zvieraťa (17)
  PREFERRED_DRIVER: "PREFERRED_DRIVER", 	// Preferovaný šofér (18)
  FOULING: "FOULING", 	// Znečistenie (18)
  STAINING: "STAINING", 	// Veľké znečistenie (19)
  /*VEHICLE_SEDAN: "VEHICLE_SEDAN", 	// Sedan (21)
  VEHICLE_COMBI: "VEHICLE_COMBI", 	// Combi (22)
  VEHICLE_CABRIO: "VEHICLE_CABRIO", 	// Cabrio (23)
  VEHICLE_COUPE: "VEHICLE_COUPE", 	// Coupé (24)
  VEHICLE_HATCHBACK: "VEHICLE_HATCHBACK", 	// Hatchback (25)
  VEHICLE_VAN: "VEHICLE_VAN", 	// Van (26)
  VEHICLE_MICROBUS: "VEHICLE_MICROBUS", 	// Microbus (27)
  VEHICLE_BUS: "VEHICLE_BUS", 	// Bus (28)
  VEHICLE_VAN_CARGO: "VEHICLE_VAN_CARGO", 	// Dodávka (29)
  VEHICLE_PICKUP: "VEHICLE_PICKUP", 	// Pickup (30)
  VEHICLE_SUV: "VEHICLE_SUV", 	// SUV (31)
  */
  PERSONS_2: "PERSONS_2", 	// Maximálny požadovaný počet osôb 2 (32)
  PERSONS_3: "PERSONS_3", 	// Maximálny požadovaný počet osôb 3 (32)
  PERSONS_4: "PERSONS_4", 	// Maximálny požadovaný počet osôb 4 (32)
  PERSONS_5: "PERSONS_5", 	// Maximálny požadovaný počet osôb 5 (32)
  PERSONS_6: "PERSONS_6", 	// Maximálny požadovaný počet osôb 6 (32)
  PERSONS_7: "PERSONS_7", 	// Maximálny požadovaný počet osôb 7 (32)
  PERSONS_8: "PERSONS_8", 	// Maximálny požadovaný počet osôb 8 (32)
  SERVICE_PERSONAL: "SERVICE_PERSONAL", 	// Osobná preprava (41)
  //SERVICE_LIMO: "SERVICE_LIMO", 	// Limuzínová preprava (42)
  //SERVICE_CARGO: "SERVICE_CARGO", 	// Nákladná preprava (43)
  //SERVICE_DRINK: "SERVICE_DRINK", 	// Drink taxi (44)
  //SERVICE_IMMOBILE: "SERVICE_IMMOBILE", 	// Preprava imobilných (45)
  //SERVICE_COURIER: "SERVICE_COURIER", 	// Kurierská služba (46)
  //SERVICE_DELIVERY: "SERVICE_DELIVERY", 	// Donášková služba (47)
  //SERVICE_AIRPORT: "SERVICE_AIRPORT", 	// Letiskové taxi (48)
  //SERVICE_SOCIAL: "SERVICE_SOCIAL", 	// Sociálne taxi (49)
  PAYMENT_CASH: "PAYMENT_CASH", 	// Hotovosť (51)
  PAYMENT_CARD: "PAYMENT_CARD", 	// Karta vo vozidle (52)
  //PAYMENT_VIAMO: "PAYMENT_VIAMO", 	// Viamo (53)
  //PAYMENT_COUPON: "PAYMENT_COUPON", 	// Kupón (54)
  PAYMENT_CARD_ONLINE: "PAYMENT_CARD_ONLINE", 	// Karta online (55)
  //PAYMENT_PREPAID: "PAYMENT_PREPAID", 	// Preplatená jazda (56)
  PAYMENT_POSTPAID: "PAYMENT_POSTPAID", 	// Platba po jazde/postpaid (57)
 // PAYMENT_INVOICE: "PAYMENT_INVOICE", 	// Platba na faktúru (58)
  PICKUP_PLACE_CHANGED: "PICKUP_PLACE_CHANGED",
  DESTINATION_PLACE_CHANGED: "DESTINATION_PLACE_CHANGED",
  WAYPOINT: "WAYPOINT", //AUTOMATICALLY ADDED
  WAYPOINT_STOP: "WAYPOINT_STOP",
  //WAYPOINT_INFO: "WAYPOINT_INFO", 	// Informácia o cene za medzizastávky (0)
  WAYPOINT_DISTRICT: "WAYPOINT_DISTRICT", 	// Extra zastávka vo štvrti (61)
  WAYPOINT_CITY: "WAYPOINT_CITY", 	// Extra zastávka v meste (62)
  BATTERY: "BATTERY", 	// Nabitie batérie (67)
  FUEL: "FUEL", 	// Poskytnutie paliva (68)
  DRAG_VEHICLE: "DRAG_VEHICLE", 	// Odtiahnutie vozidla (69)
  REGISTRATION: "REGISTRATION", 	// Registrácia (71)
  BLOCKING: "BLOCKING", 	// Zablokovanie (72)
  UNBLOCKING: "UNBLOCKING", 	// Odblokovanie (73)
  SOURCE_DISPATCH_TAXISERVICE: "SOURCE_DISPATCH_TAXISERVICE", 	// Objednávka z dispečingu (74)
  //SOURCE_DISPATCH_BROKER: "SOURCE_DISPATCH_BROKER", 	// Objednávka z dispečingu (74)
  //SOURCE_DISPATCH_TAXXIMO: "SOURCE_DISPATCH_TAXXIMO", 	// Objednávka z dispečingu (74)
  SOURCE_DRIVER: "SOURCE_DRIVER", 	// Objednávka od vodiča z ulice (75)
  SOURCE_APP: "SOURCE_APP", 	// Objednávka z aplikácie zákazníka (76)
  SOURCE_BRANDAPP: "SOURCE_BRANDAPP", 	// Objednávka od brandovanej aplikácie zákazníka (77)
  ACCOUNT: "ACCOUNT", 	// Zmluvná/firemný jazda (78)
  //STORNO_PREREGISTERED: "STORNO_PREREGISTERED", 	 	// STORNO PREREGISTERED -> Odregistrovaná/Unregistered (88)
  //STORNO_NEW: "STORNO_NEW", 	 	// STORNO Nová -> Nerealizovaná/Expired  (81)
  //STORNO_PLANNED: "STORNO_PLANNED", 	 	// STORNO Plánovaná -> Odvolaná/Revoked  (82)
  //STORNO_PENDING: "STORNO_PENDING", 	 	// STORNO Čakajúca -> Odmietnutá/Declined  (83)
  //STORNO_ACCEPTED: "STORNO_ACCEPTED", 	 	// STORNO Akcpetovaná -> Zrušená/Canceled  (84)
  //STORNO_WAITING: "STORNO_WAITING", 	 	// STORNO Na mieste -> Zamietnutá/Dismissed  (85)
  //STORNO_IN_PROGRESS: "STORNO_IN_PROGRESS", 	 	// STORNO Realizovaná -> Nedokončená/Unfinished  (86)
  //STORNO_FINISHED: "STORNO_FINISHED", 	 	// STORNO Ukončená -> Nenaúčtovaná/Uncharged  (87)
  CUSTOMER_NEW: "CUSTOMER_NEW", 	// Nový zákazník
  CUSTOMER_REGULAR: "CUSTOMER_REGULAR", 	// Stály zákazník
  CUSTOMER_PRIORITY: "CUSTOMER_PRIORITY", 	// Prioritný zákazník
  CUSTOMER_FAMOUS: "CUSTOMER_FAMOUS", 	// Známa osobnosť
  CUSTOMER_VIP: "CUSTOMER_VIP", 	// VIP klient
  CUSTOMER_COLLEAGUE: "CUSTOMER_COLLEAGUE", 	// Kolega z taxislužby
  CUSTOMER_STUDENT: "CUSTOMER_STUDENT", 	// Študent
  CUSTOMER_CHILD: "CUSTOMER_CHILD", 	// Dieťa
  CUSTOMER_JUNIOR: "CUSTOMER_JUNIOR", 	// Junior
  CUSTOMER_SENIOR: "CUSTOMER_SENIOR", 	// Senior
  CUSTOMER_DEBTOR: "CUSTOMER_DEBTOR", 	// Dlžník
  CUSTOMER_BLOCKED: "CUSTOMER_BLOCKED", 	// Na zozname zakázaných zákazníkov
  SOURCE_BRANDAPP_FIRST_TWO_ORDERS: "SOURCE_BRANDAPP_FIRST_TWO_ORDERS", 	// Objednávka od brandovanej aplikácie zákazníka (77)
  SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: "SOURCE_BRANDAPP_EVERY_FIVE_ORDERS" 	// Objednávka od brandovanej aplikácie zákazníka (77)
});


export const Operation = Object.freeze({
  NONE: "NONE", 	// Nezadaný (0)
  ONE_OFF: "ONE_OFF", 	// Jednorázový (1)
  PERC_PRICE_DRIVE: "PERC_PRICE_DRIVE", 	//Percento k cene prepravy (2)  ==> premenovať z PERC_TRANSFER na PERC_PRICE_DRIVE
  PERC_PRICE_JOURNEY: "PERC_PRICE_JOURNEY", 	// Percento k sumárnej cene (3) ==> premenovať z PERC_SUMMARY na PERC_PRICE_JOURNEY
  DRIVE_TIME: "DRIVE_TIME", 	// Za minútu jazdy (4)
  DRIVE_DISTANCE: "DRIVE_DISTANCE", 	// Za kilometer jazdy (5)
  WAITING_TIME: "WAITING_TIME", 	// Za minútu čakania v stave 6 - za kazdu zacatu minutu (6)
  WAITING_TIME_EXACT: "WAITING_TIME_EXACT", 	// Za minútu čakania v stave 6 presne na sekundy, resp. min 3 desatinne miesta(6)
  //WAITING_PICKUP_TIME: "WAITING_PICKUP_TIME" 	// Za minútu čakania v stave 5 (5)
});

export const Automatic = Object.freeze({
  MANUAL: "MANUAL", 	// Vodič môže manuálne pridať príplatok (0)
  AUTO: "AUTO"	// Príplatok bude automaticky pridaný k účtu (1)
});

export const Unit = Object.freeze({
  KM: "KM",
  M: "M",
  S: "S",
  MIN: "MIN",
  H: "H",
  KS: "KS"
});