<template>
  <div>
    <v-btn class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :items-per-page="40"
      sort-by.sync="name"
      sort-desc.sync="true"
      class="elevation-1"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>
      <template v-slot:[`item.paymentType`]="{ item }">
        {{ $t("paymentType." + item.paymentType) }}
      </template>
      <template v-slot:[`item.receiptType`]="{ item }">
        {{ $t("receiptType." + item.receiptType) }}
      </template>
      <template v-slot:[`item.receiptStatus`]="{ item }">
        {{ $t("receiptStatus." + item.receiptStatus) }}
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="openEditDialog(item)">
          mdi-square-edit-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("PaymentOptionList.dialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <!-- :items="Object.keys(PaymentType)" -->
                  <v-select
                    :label="$t('PaymentOptionList.paymentType')"
                    filled
                    v-model="editItem.paymentType"
                    :items="$t('paymentTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :disabled="typeFieldDisabled"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- :items="Object.keys(ReceiptType)" -->
                  <v-select
                    :label="$t('PaymentOptionList.receiptType')"
                    filled
                    v-model="editItem.receiptType"
                    :items="$t('receiptTypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- :items="Object.keys(PaymeReceiptStatusntType)" -->
                  <v-select
                    :label="$t('PaymentOptionList.receiptStatus')"
                    filled
                    v-model="editItem.receiptStatus"
                    :items="$t('receiptStatusArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.active"
                    :label="$t('PaymentOptionList.active')"
                    required
                    filled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('PaymentOptionList.note')"
                    filled
                    v-model="editItem.note"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn
              color="success"
              :loading="loadingSaveItem"
              text
              @click.stop="submitEditDialog()"
              >{{ $t("btnSave") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { Type as PaymentType } from "../enums/PaymentEnum";
import {
  Type as ReceiptType,
  Status as ReceiptStatus,
} from "../enums/ReceiptEnum";
export default {
  name: "PaymentOptionList",
  data() {
    return {
      PaymentType,
      ReceiptType,
      ReceiptStatus,
      gridHeaders: [
        {
          text: this.$t("PaymentOptionList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("PaymentOptionList.tableHeaders.active"),
          value: "active",
        },
        {
          text: this.$t("PaymentOptionList.tableHeaders.paymentType"),
          value: "paymentType",
        },
        {
          text: this.$t("PaymentOptionList.tableHeaders.receiptType"),
          value: "receiptType",
        },
        {
          text: this.$t("PaymentOptionList.tableHeaders.receiptStatus"),
          value: "receiptStatus",
        },
        { text: this.$t("PaymentOptionList.tableHeaders.note"), value: "note" },
      ],
      gridItems: [],

      loadingData: false,
      loadingSaveItem: false,

      editDefaultItem: {
        id: null,
        paymentType: PaymentType.CASH,
        active: false,
        receiptType: ReceiptType.INVOICE,
        receiptStatus: ReceiptStatus.PAID,
        note: "",
      },
      editItem: {},
      editDialog: false,
      typeFieldDisabled: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {},

  mounted() {
    this.getAll();
  },

  methods: {
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/payment/option`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
          this.gridItemsClone = JSON.parse(JSON.stringify(this.gridItems));
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    openCreateDialog() {
      this.editItem = Object.assign({}, this.editDefaultItem);
      this.typeFieldDisabled = false;
      this.editDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    openEditDialog(item) {
      this.editItem = Object.assign({}, item);
      this.typeFieldDisabled = true;
      this.editDialog = true;
    },

    submitEditDialog() {
      const isValid = this.$refs.formCreate.validate();
      //check if this.editItem.paymentType is already in gridItems
      if (this.typeFieldDisabled == false) {
        const isExist = this.gridItems.find(
          (x) => x.paymentType == this.editItem.paymentType
        );
        if (isExist) {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "PaymentOptionList.msgErrorAlreadyUsedType"
          );
          this.snackbar.color = "error";
          return;
        }
      }
      if (isValid) {
        this.loadingSaveItem = true;
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/payment/option`,
            this.editItem
          )
          .then((response) => {
            this.editDialog = false;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";

            this.loadingSaveItem = false;
            this.getAll();
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";

            this.loadingSaveItem = false;
          });
      }
    },
  },
};
</script>
