export const Status = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  HIDDEN: "HIDDEN",
});

export const Type = Object.freeze({
  ALL: "ALL",
  DRIVER: "DRIVER",
  HIDDEN: "HIDDEN",
});

export const FareType = Object.freeze({
  DEFAULT: "DEFAULT",
  LOCALITY: "LOCALITY",
});

export const FareJourneyMinimumType = Object.freeze({
  DEFAULT: "DEFAULT",
  LOCALITY: "LOCALITY",
});

export const FareChangeToDefault = Object.freeze({
  NONE: "NONE",
  WAITING: "WAITING",
  WAYPOINT: "WAYPOINT",
  ACCOUNT: "ACCOUNT",
  WAITING_WAYPOINT: "WAITING_WAYPOINT",
  WAITING_ACCOUNT: "WAITING_ACCOUNT",
  WAYPOINT_ACCOUNT: "WAYPOINT_ACCOUNT",
  WAITING_WAYPOINT_ACCOUNT: "WAITING_WAYPOINT_ACCOUNT",
});

export const FareDriveType = Object.freeze({
  FIXED: "FIXED",
  DISTANCE: "DISTANCE",
});

export const FareStartType = Object.freeze({
  FIXED: "FIXED", // Pevná suma
  FROM_STAND: "FROM_STAND", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa, ostatné sú defaultné hodnoty (2)
  TO_STAND: "TO_STAND", // PRÍSTAVNÉ obsahuje jazdu na Stanovište, ostatné sú defaultné hodnoty (3)
  BETWEEN_STAND: "BETWEEN_STAND", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište, ostatné sú defaultné hodnoty (4)
});

export const Service = Object.freeze({
  PERSONAL: "PERSONAL",
  LIMO: "LIMO",
  CARGO: "CARGO",
  DRINK: "DRINK",
  IMMOBILE: "IMMOBILE",
  COURIER: "COURIER",
  DELIVERY: "DELIVERY",
  AIRPORT: "AIRPORT",
  SOCIAL: "SOCIAL",
});

export const ShowPrice = Object.freeze({
  HIDDEN: "HIDDEN",
  ONLY_DRIVER: "ONLY_DRIVER",
  ALL: "ALL",
});
